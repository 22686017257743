import axios from "axios";
import { ApiResponse } from "./types/responses";

export class ApolloApi {
  url: string;

  constructor({ url }: { url?: string }) {
    this.url = url || "https://dna.phxapollo.com/api/v1";
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get<Response = any, Params = any>({
    url,
    params,
    token,
  }: {
    url: string;
    params: Params;
    token: string;
  }): Promise<ApiResponse> {
    try {
      const response = await axios<Response>({
        method: "get",
        url: `${this.url}${url}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      return {
        status: 200,
        data: response.data,
        error: null,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          status: error.response?.status || 400,
          data: null,
          error,
        };
      } else {
        throw error;
      }
    }
  }
}
