export enum LeagueId {
  Wnba = "10",
  GLeague = "20",
  NBA = "00",
  SacramentoSummerLeague = "13",
  OrlandoSummerLeague = "14",
  VegasSummerLeague = "15",
  UtahSummerLeague = "16",
  Nba2k5v5 = "12",
  Nba2k3v3 = "22",
}
