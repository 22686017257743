import { LeagueId } from "@suns/api";

export function getLeagueIdFromGameId(gameId: string) {
  const leagueId = gameId.substring(0, 2) as LeagueId;

  if (!Object.values(LeagueId).includes(leagueId)) {
    throw Error(`League ID ${leagueId} isn't valid (pulled from ${gameId})`);
  }

  return leagueId;
}
