import React from "react";
import { Badge, BadgeProps } from "../Badge/Badge";
import { cn } from "../../utils";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: React.ReactElement;
  badge?: string | ReturnType<typeof Badge>;
  badgeProps?: BadgeProps;
}

export function Card({
  className,
  header,
  badge,
  badgeProps,
  children,
  ...props
}: CardProps) {
  const renderBadge =
    typeof badge == "string" ? (
      <Badge {...badgeProps}>{badge}</Badge>
    ) : badge ? (
      badge
    ) : null;

  return (
    <div
      className={cn(
        "relative rounded-lg border bg-card p-4 text-card-foreground shadow-sm",
        className
      )}
      {...props}
    >
      {header ? (
        <div className="-mx-4 mb-4 border-b px-4 pb-4">{header}</div>
      ) : null}
      {renderBadge ? (
        <div className="transform-center absolute left-1/2 top-0">
          {renderBadge}
        </div>
      ) : null}
      {children}
    </div>
  );
}
