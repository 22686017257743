import Table from "../../../components/table/table";
import {
  FiltersService,
  LeagueData,
  OffenseResponse,
  StatData,
  TeamDnaService,
  TeamLeagueData,
  WinsLosses,
} from "../../../swagger";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  GameResults,
  SEASON_TYPES_LIST,
  SeasonTypes,
  TableTypes,
  TeamDnaDataTypes,
} from "../../../types/enums/teamDna.enums";
import { useDispatch } from "react-redux";
import { Modal } from "react-responsive-modal";
import { setPrintRef } from "../../../redux/reducers/print.reducer";
import { fieldsWithReverseOrder } from "../../../shared/utils/teamDna-constant";
import dayjs, { Dayjs } from "dayjs";
import Skeleton from "react-loading-skeleton";
import { columns, modalColumns } from "../team-dna-columns";
import {
  AllLoader,
  AllSelectedTeamData,
  AllTeamsData,
  FilterData,
  SelectedTeamData,
  SetPlayTypesTeamDNAData,
  SetTeamDNAData,
  defaultFilter,
} from "../team-dna-types";
import TeamDNAFilters from "../component/team-dna-filters";
import { FiltersSelectedType } from "../team-dna-types";
import { convertPlayers, dedupe } from "../../../shared/utils/helper-functions";
import { useSearchParams } from "react-router-dom";
import { suns, teams } from "../../../shared/utils/teams";

const TeamDNA = () => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);

  const [selectedTeamData, setSelectedTeamData] = useState<AllSelectedTeamData>(
    new SetTeamDNAData()
  );
  const [selectedPlayTypesTeamData, setSelectedPlayTypesTeamData] =
    useState<AllSelectedTeamData>(new SetPlayTypesTeamDNAData());

  const [tableLoader, setTableLoader] = useState<AllLoader>({
    [TeamDnaDataTypes.TEAM_DNA]: {
      [TableTypes.OFFENSE]: true,
      [TableTypes.DEFENSE]: true,
    },
    [TeamDnaDataTypes.PLAY_TYPES]: {
      [TableTypes.OFFENSE]: true,
      [TableTypes.DEFENSE]: true,
    },
  });

  const [filterSelectedData, setFiltersSelectedData] =
    useState<FiltersSelectedType>(new FiltersSelectedType(defaultFilter));

  const [selectedFilters, setSelectedFilters] = useState<FiltersSelectedType>(
    new FiltersSelectedType(defaultFilter)
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalHeadingData, setModalHeadingData] = useState<{
    stat: string;
    avg: string;
    team: string | undefined;
    value: string | null;
  }>({ stat: "", avg: "", team: "", value: null });
  const [modalTableData, setModalTableData] = useState<
    Array<{ rank: string; team: string; value: string }>
  >([]);
  const [allTeamsData, setAllTeamsData] = useState<AllTeamsData>({
    [TableTypes.OFFENSE]: [],
    [TableTypes.DEFENSE]: [],
  });
  const [playTypesTeamsData, setPlayTypesTeamsData] = useState<AllTeamsData>({
    [TableTypes.OFFENSE]: [],
    [TableTypes.DEFENSE]: [],
  });

  const [isFiltersVisible, setIsFiltersVisible] = useState(true);
  const [teamWinsLossesCount, setTeamWinsLossesCount] = useState<WinsLosses>();
  const [teamWinsLossesLoader, setTeamWinsLossesLoader] =
    useState<boolean>(false);

  const [playersListFilters, setPlayersListFilters] = useState<{
    gamesWithPlayersList: {
      value: number;
      label: string;
      isDisabled: boolean;
    }[];
    gamesWithoutPlayersList: {
      value: number;
      label: string;
      isDisabled: boolean;
    }[];
    gamesStartedByPlayersList: {
      value: number;
      label: string;
      isDisabled: boolean;
    }[];
    gamesNotStartedByPlayersList: {
      value: number;
      label: string;
      isDisabled: boolean;
    }[];
  }>({
    gamesWithPlayersList: [],
    gamesWithoutPlayersList: [],
    gamesStartedByPlayersList: [],
    gamesNotStartedByPlayersList: [],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const seasonsList = selectedFilters.selectedSeasons.map(
      (season) => season.value
    );
    if (selectedFilters.selectedTeam && seasonsList.length) {
      FiltersService.getPlayers(
        seasonsList,
        selectedFilters.selectedTeam.value
      ).then((res) => {
        const players: {
          value: number;
          label: string;
          isDisabled: boolean;
        }[] = [];
        res.data.forEach((player) => {
          players.push({
            value: player.NBA_PLAYER_ID,
            label: player.LAST_NAME + ", " + player.FIRST_NAME,
            isDisabled: false,
          });
        });

        const removedDuplicatePlayers = players.reduce(
          (acc, player) => {
            if (
              acc.findIndex(
                (p: { value: number }) => p.value === player.value
              ) === -1
            ) {
              acc.push(player);
            }
            return acc;
          },
          [] as { value: number; label: string; isDisabled: boolean }[]
        );

        setPlayersListFilters({
          gamesWithPlayersList: removedDuplicatePlayers,
          gamesWithoutPlayersList: removedDuplicatePlayers,
          gamesStartedByPlayersList: removedDuplicatePlayers,
          gamesNotStartedByPlayersList: removedDuplicatePlayers,
        });
      });
    }
  }, [selectedFilters.selectedSeasons, selectedFilters.selectedTeam]);

  function convertDatesToDayjsTuple(
    dateStrings: (string | null)[]
  ): [Dayjs | null, Dayjs | null] {
    if (dateStrings.includes("null")) {
      return [null, null];
    } else {
      const date1 =
        dateStrings.length > 0 && dateStrings[0] ? dayjs(dateStrings[0]) : null;
      const date2 =
        dateStrings.length > 1 && dateStrings[1] ? dayjs(dateStrings[1]) : null;
      return [date1, date2];
    }
  }

  const incomingSearchParams = {
    teamId: searchParams.get("teamId"),
    oppTeamId: searchParams.get("oppTeamId"),
    seasons: searchParams.getAll("season"),
    seasonTypes: searchParams.getAll("seasonTypes") as SeasonTypes[],
    dates: searchParams.getAll("dates"),
    noOfGames:
      searchParams.get("noOfGames") === "undefined"
        ? undefined
        : parseInt(searchParams.get("noOfGames") || "0"),
    gameResults: searchParams.getAll("gameResults") as GameResults[],
    perGame: searchParams.get("perGame"),
    gamesWithPlayer: searchParams.getAll("gamesWithPlayer"),
    gamesWithoutPlayer: searchParams.getAll("gamesWithoutPlayer"),
    gamesPlayerStarted: searchParams.getAll("gamesStartedByPlayer"),
    gamesPlayerNotStarted: searchParams.getAll("gamesNotStartedByPlayer"),
    isGarbageExcluded: searchParams.get("garbageTime") === "true",
  };

  const memoizedGamesWithPlayer = useMemo(() => {
    return convertPlayers(
      incomingSearchParams.gamesWithPlayer,
      playersListFilters.gamesWithPlayersList
    );
  }, [
    incomingSearchParams.gamesWithPlayer,
    playersListFilters.gamesWithPlayersList,
  ]);
  const memoizedGamesWithoutPlayer = useMemo(() => {
    return convertPlayers(
      incomingSearchParams.gamesWithoutPlayer,
      playersListFilters.gamesWithoutPlayersList
    );
  }, [
    incomingSearchParams.gamesWithoutPlayer,
    playersListFilters.gamesWithoutPlayersList,
  ]);
  const memoizedGamesPlayerStarted = useMemo(() => {
    return convertPlayers(
      incomingSearchParams.gamesPlayerStarted,
      playersListFilters.gamesStartedByPlayersList
    );
  }, [
    incomingSearchParams.gamesPlayerStarted,
    playersListFilters.gamesStartedByPlayersList,
  ]);
  const memoizedGamesPlayerNotStarted = useMemo(() => {
    return convertPlayers(
      incomingSearchParams.gamesPlayerNotStarted,
      playersListFilters.gamesNotStartedByPlayersList
    );
  }, [
    incomingSearchParams.gamesPlayerNotStarted,
    playersListFilters.gamesNotStartedByPlayersList,
  ]);

  useEffect(() => {
    const getTeamById = (teamId: string | undefined) => {
      if (!teamId) return null;
      const team =
        teams.find((team) => team.TEAM_ID === parseInt(teamId)) || suns;
      return {
        value: team.TEAM_ABBR,
        label: team.TEAM_FULL_NAME,
        id: team.TEAM_ID,
      };
    };

    const filteredParams = {
      selectedTeam: incomingSearchParams.teamId
        ? getTeamById(incomingSearchParams.teamId)
        : getTeamById(`${suns.TEAM_ID}`),
      selectedOpponent:
        incomingSearchParams.oppTeamId !== null
          ? getTeamById(incomingSearchParams.oppTeamId || "")
          : null,
      selectedSeasons: incomingSearchParams.seasons.length
        ? incomingSearchParams.seasons.map((season) => ({
            value: season,
            label: season,
          }))
        : [{ value: "2023-24", label: "2023-24" }],
      selectedSeasonTypes: incomingSearchParams.seasonTypes.length
        ? incomingSearchParams.seasonTypes
        : defaultFilter.selectedSeasonTypes,
      dateRange: convertDatesToDayjsTuple(incomingSearchParams.dates),
      noOfGames:
        incomingSearchParams.noOfGames === 0
          ? undefined
          : incomingSearchParams.noOfGames,
      selectedGameResults: incomingSearchParams.gameResults.length
        ? incomingSearchParams.gameResults
        : [GameResults.WIN, GameResults.LOSS],
      isPerGameSelected: incomingSearchParams.perGame ? true : false,
      gamesWithPlayer: memoizedGamesWithPlayer,
      gamesWithoutPlayer: memoizedGamesWithoutPlayer,
      gamesStartedByPlayer: memoizedGamesPlayerStarted,
      gamesNotStartedByPlayer: memoizedGamesPlayerNotStarted,
      isGarbageExcluded: searchParams.get("garbageTime") === "true",
    };

    if (searchParams.size > 0) {
      setFiltersSelectedData(filteredParams);
    } else {
      setFiltersSelectedData(new FiltersSelectedType(defaultFilter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    memoizedGamesWithPlayer.length,
    memoizedGamesWithoutPlayer.length,
    memoizedGamesPlayerStarted.length,
    memoizedGamesPlayerNotStarted.length,
  ]);

  useEffect(() => {
    getWinsLosses();
    getTeamDNAData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelectedData]);

  useEffect(() => {
    const loaders = Object.values(tableLoader);
    const isAnyLoading = loaders.some((loader) => {
      return Object.values(loader).includes(true);
    });
    dispatch(setPrintRef(isAnyLoading ? null : componentRef.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableLoader]);

  const getTeamDNAData = () => {
    setSelectedTeamData(new SetTeamDNAData());
    setTableLoaderCallback(TableTypes.OFFENSE, TeamDnaDataTypes.TEAM_DNA);
    setTableLoaderCallback(TableTypes.DEFENSE, TeamDnaDataTypes.TEAM_DNA);
    setTableLoaderCallback(TableTypes.DEFENSE, TeamDnaDataTypes.PLAY_TYPES);
    setTableLoaderCallback(TableTypes.OFFENSE, TeamDnaDataTypes.PLAY_TYPES);
    setAllTeamsData({ [TableTypes.OFFENSE]: [], [TableTypes.DEFENSE]: [] });
    setPlayTypesTeamsData({
      [TableTypes.OFFENSE]: [],
      [TableTypes.DEFENSE]: [],
    });
    setSelectedPlayTypesTeamData(new SetPlayTypesTeamDNAData());
    getTeamData(TableTypes.OFFENSE, TeamDnaDataTypes.TEAM_DNA);
    getTeamData(TableTypes.DEFENSE, TeamDnaDataTypes.TEAM_DNA);
    setTimeout(() => {
      getTeamData(TableTypes.OFFENSE, TeamDnaDataTypes.PLAY_TYPES);
      getTeamData(TableTypes.DEFENSE, TeamDnaDataTypes.PLAY_TYPES);
    }, 1000);
  };

  const setTableLoaderCallback = (
    type: TableTypes,
    teamDnaType: TeamDnaDataTypes,
    isTableLoading = true
  ) => {
    setTableLoader((tableLoader: AllLoader) => {
      const tableLoaderTemp = { ...tableLoader };
      tableLoaderTemp[teamDnaType][type] = isTableLoading;
      return tableLoaderTemp;
    });
  };

  const getFiltersData = (): FilterData => {
    const gameDates = getGameDates();
    const seasonsList = filterSelectedData.selectedSeasons.map(
      (season) => season.value
    );
    const gamesWithPlayerList = filterSelectedData.gamesWithPlayer?.map(
      (player) => player.value?.toString()
    );
    const gamesWithoutPlayerList = filterSelectedData.gamesWithoutPlayer?.map(
      (player) => player.value?.toString()
    );
    const gamesStartedByPlayerList =
      filterSelectedData.gamesStartedByPlayer?.map((player) =>
        player.value?.toString()
      );
    const gamesNotStartedByPlayerList =
      filterSelectedData.gamesNotStartedByPlayer?.map((player) =>
        player.value?.toString()
      );

    return [
      filterSelectedData.selectedSeasonTypes,
      filterSelectedData.selectedTeam?.value || "",
      filterSelectedData.selectedGameResults,
      seasonsList,
      filterSelectedData.selectedOpponent?.value,
      !filterSelectedData.isGarbageExcluded,
      !filterSelectedData.isPerGameSelected,
      gameDates,
      filterSelectedData.noOfGames,
      gamesWithPlayerList,
      gamesWithoutPlayerList,
      gamesStartedByPlayerList,
      gamesNotStartedByPlayerList,
    ];
  };

  const getTeamData = (type: TableTypes, teamDnaType: TeamDnaDataTypes) => {
    if (filterSelectedData.selectedTeam) {
      const filterData = getFiltersData();
      const req =
        teamDnaType == TeamDnaDataTypes.TEAM_DNA
          ? type === TableTypes.OFFENSE
            ? TeamDnaService.getOffenseData(...filterData)
            : TeamDnaService.getDefenseData(...filterData)
          : type === TableTypes.OFFENSE
            ? TeamDnaService.getOffensePlayTypesData(...filterData)
            : TeamDnaService.getDefensePlayTypesData(...filterData);

      const teamLeagueAvgData = getTeamAvgData(type, teamDnaType);
      req
        .then(async (response: OffenseResponse) => {
          if (response) {
            if (teamDnaType === TeamDnaDataTypes.TEAM_DNA) {
              setAllTeamsData((allTeamsData: AllTeamsData) => {
                const allTeamsDataTemp: AllTeamsData = { ...allTeamsData };
                allTeamsDataTemp[type] = response.data;
                return allTeamsDataTemp;
              });
            } else {
              setPlayTypesTeamsData((playTypesTeamsData: AllTeamsData) => {
                const playTypesTeamsDataTemp: AllTeamsData = {
                  ...playTypesTeamsData,
                };
                playTypesTeamsDataTemp[type] = response.data;
                return playTypesTeamsDataTemp;
              });
            }
            const selectedTeamData: LeagueData | undefined = response.data.find(
              (team: TeamLeagueData) =>
                team.teamName === filterSelectedData.selectedTeam?.value
            )?.teamData;
            if (selectedTeamData) {
              const teamLeagueAvg = await teamLeagueAvgData;
              setLeagueAvgData(selectedTeamData, teamLeagueAvg);
              const resultArray: SelectedTeamData[] = [];
              for (const key in selectedTeamData) {
                if (
                  Object.prototype.hasOwnProperty.call(selectedTeamData, key)
                ) {
                  resultArray.push({
                    title: selectedTeamData[key as keyof LeagueData].title,
                  });
                  for (const subKey in selectedTeamData[
                    key as keyof LeagueData
                  ]) {
                    if (subKey !== "title") {
                      resultArray.push(
                        selectedTeamData[key as keyof LeagueData][
                          subKey as keyof LeagueData[keyof LeagueData]
                        ] as SelectedTeamData
                      );
                    }
                  }
                }
              }
              if (teamDnaType === TeamDnaDataTypes.TEAM_DNA) {
                setSelectedTeamData((selectedTeamData: AllSelectedTeamData) => {
                  const selectedTeamDataTemp: AllSelectedTeamData = {
                    ...selectedTeamData,
                  };
                  selectedTeamDataTemp[type] = resultArray;
                  return selectedTeamDataTemp;
                });
              } else {
                setSelectedPlayTypesTeamData(
                  (selectedPlayTypesTeamData: AllSelectedTeamData) => {
                    const selectedTeamDataTemp: AllSelectedTeamData = {
                      ...selectedPlayTypesTeamData,
                    };
                    selectedTeamDataTemp[type] = resultArray;
                    return selectedTeamDataTemp;
                  }
                );
              }
            }
            setTableLoaderCallback(type, teamDnaType, false);
          }
        })
        .catch(() => {
          setTableLoaderCallback(type, teamDnaType, false);
        });
    }
  };

  const getGameDates = () => {
    const gameDates = [];
    if (filterSelectedData.dateRange[0] && filterSelectedData.dateRange[1]) {
      const startDate = dayjs(filterSelectedData.dateRange[0]).toDate();
      const endDate = dayjs(filterSelectedData.dateRange[1]).toDate();
      gameDates.push(startDate.toISOString().split("T")[0]);
      gameDates.push(endDate.toISOString().split("T")[0]);
    }
    return gameDates;
  };

  const getSeasonFromDate = (day: Dayjs) => {
    const seasons = [];

    if (day.month() > 9) {
      seasons.push(`${day.year()}`, `${day.year() + 1}`);
    } else {
      seasons.push(`${day.year() - 1}`, `${day.year()}`);
    }

    if (seasons[0].slice(-2) !== "99") {
      seasons[1] = seasons[1].slice(-2);
    }

    return seasons.join("-");
  };

  const getSeasonsList = () => {
    const { dateRange } = filterSelectedData;

    if (dateRange[0] && dateRange[1]) {
      return dedupe([
        getSeasonFromDate(dateRange[0]),
        getSeasonFromDate(dateRange[1]),
      ]);
    } else {
      return filterSelectedData.selectedSeasons.map((season) => season.value);
    }
  };

  const getTeamAvgData = async (
    type: TableTypes,
    teamDnaType: TeamDnaDataTypes
  ) => {
    if (filterSelectedData.selectedTeam) {
      const gameDates = getGameDates();
      const seasonsList = getSeasonsList();
      const res =
        teamDnaType === TeamDnaDataTypes.TEAM_DNA
          ? type == TableTypes.OFFENSE
            ? await TeamDnaService.getOffenseLeagueAvgData(
                filterSelectedData.selectedSeasonTypes,
                filterSelectedData.selectedGameResults,
                filterSelectedData.selectedTeam?.value,
                seasonsList,
                !filterSelectedData.isGarbageExcluded,
                gameDates,
                filterSelectedData.noOfGames,
                !filterSelectedData.isPerGameSelected,
                filterSelectedData.selectedOpponent?.value
              )
            : await TeamDnaService.getDefenseLeagueAvgData(
                filterSelectedData.selectedSeasonTypes,
                filterSelectedData.selectedGameResults,
                filterSelectedData.selectedTeam?.value,
                seasonsList,
                !filterSelectedData.isGarbageExcluded,
                gameDates,
                filterSelectedData.noOfGames,
                !filterSelectedData.isPerGameSelected,
                filterSelectedData.selectedOpponent?.value
              )
          : type == TableTypes.OFFENSE
            ? await TeamDnaService.getOffensePlayTypesLeagueAvgData(
                filterSelectedData.selectedSeasonTypes,
                filterSelectedData.selectedGameResults,
                filterSelectedData.selectedTeam?.value,
                seasonsList,
                !filterSelectedData.isGarbageExcluded,
                gameDates,
                filterSelectedData.noOfGames,
                !filterSelectedData.isPerGameSelected,
                filterSelectedData.selectedOpponent?.value
              )
            : await TeamDnaService.getDefensePlayTypesLeagueAvgData(
                filterSelectedData.selectedSeasonTypes,
                filterSelectedData.selectedGameResults,
                filterSelectedData.selectedTeam?.value,
                seasonsList,
                !filterSelectedData.isGarbageExcluded,
                gameDates,
                filterSelectedData.noOfGames,
                !filterSelectedData.isPerGameSelected,
                filterSelectedData.selectedOpponent?.value
              );

      return res.data;
    }
  };

  const getWinsLosses = () => {
    const gameDates = getGameDates();
    if (filterSelectedData.selectedTeam) {
      setTeamWinsLossesLoader(true);
      const seasonsList = filterSelectedData.selectedSeasons.map(
        (season) => season.value
      );
      const gamesWithPlayerList = filterSelectedData.gamesWithPlayer?.map(
        (player) => player.value?.toString()
      );
      const gamesWithoutPlayerList = filterSelectedData.gamesWithoutPlayer?.map(
        (player) => player.value?.toString()
      );
      const gamesStartedByPlayerList =
        filterSelectedData.gamesStartedByPlayer?.map((player) =>
          player.value?.toString()
        );
      const gamesNotStartedByPlayerList =
        filterSelectedData.gamesNotStartedByPlayer?.map((player) =>
          player.value?.toString()
        );
      TeamDnaService.getWinsLosses(
        filterSelectedData.selectedSeasonTypes,
        filterSelectedData.selectedTeam?.value,
        filterSelectedData.selectedGameResults,
        seasonsList,
        filterSelectedData.selectedOpponent?.value,
        !filterSelectedData.isGarbageExcluded,
        !filterSelectedData.isPerGameSelected,
        gameDates,
        filterSelectedData.noOfGames,
        gamesWithPlayerList,
        gamesWithoutPlayerList,
        gamesStartedByPlayerList,
        gamesNotStartedByPlayerList
      )
        .then(async (response) => {
          setTeamWinsLossesCount(response.data);
          setTeamWinsLossesLoader(false);
        })
        .catch(() => {
          setTeamWinsLossesLoader(false);
        });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setLeagueAvgData = (data: any, leagueAvgData: any) => {
    for (const key in leagueAvgData) {
      for (const subKey in leagueAvgData[key]) {
        if (data?.[key]?.[subKey]) {
          data[key][subKey].leagueAvg = leagueAvgData[key][subKey]
            ? leagueAvgData[key][subKey]
            : "--";
        }
      }
    }
  };

  const handleTableRowClick = (
    row: { values: { stat: string; leagueAvg: string; value: string } },
    tableType?: TableTypes
  ) => {
    const tableData: {
      rank: string;
      team: string;
      value: string;
    }[] = [];
    setModalHeadingData({
      stat: row.values.stat,
      avg: row.values.leagueAvg,
      team: filterSelectedData.selectedTeam?.value,
      value: row.values.value,
    });
    let modalTableData = { ...allTeamsData };
    if (
      row.values.stat.includes("EFFICIENCY") ||
      row.values.stat.includes("VOLUME")
    ) {
      modalTableData = { ...playTypesTeamsData };
    }

    if (tableType && modalTableData && modalTableData[tableType]) {
      modalTableData[tableType].forEach((team: TeamLeagueData) => {
        for (const category in team.teamData) {
          for (const factor in team.teamData[category as keyof LeagueData]) {
            const factorData: StatData | string =
              team.teamData[category as keyof LeagueData][
                factor as keyof LeagueData[keyof LeagueData]
              ];
            if (typeof factorData == "object") {
              const factorDataTemp: StatData = factorData as StatData;
              if (factorDataTemp && factorDataTemp.stat === row.values.stat) {
                tableData.push({
                  rank: factorDataTemp.rank as unknown as string,
                  team: team.teamName,
                  value: factorDataTemp.value,
                });
              }
            }
          }
        }
      });
    }

    tableData.sort(
      (a: { rank: string | number }, b: { rank: string | number }) => {
        const rankA =
          typeof a.rank === "string" ? parseInt(a.rank.split("-")[1]) : a.rank;
        const rankB =
          typeof b.rank === "string" ? parseInt(b.rank.split("-")[1]) : b.rank;
        return rankA - rankB;
      }
    );

    const avgData = { rank: " ", team: "AVG", value: row.values.leagueAvg };

    let insertIndex;
    if (tableType === TableTypes.DEFENSE) {
      if (fieldsWithReverseOrder.includes(row.values.stat)) {
        insertIndex = tableData.findIndex(
          (row: { value: string }) =>
            Number(row.value.replace(/[^0-9.]/g, "")) >
            Number(avgData.value.replace(/[^0-9.]/g, ""))
        );
        if (insertIndex === -1) {
          insertIndex = tableData.length;
        }
      } else {
        insertIndex = tableData.findIndex(
          (row: { value: string }) =>
            Number(row.value.replace(/[^0-9.]/g, "")) <
            Number(avgData.value.replace(/[^0-9.]/g, ""))
        );
        if (insertIndex === -1) {
          insertIndex = tableData.length;
        }
      }
    } else {
      if (row.values.stat === "TURNOVERS RATE") {
        insertIndex = tableData.findIndex(
          (row: { value: string }) =>
            Number(row.value.replace(/[^0-9.]/g, "")) >
            Number(avgData.value.replace(/[^0-9.]/g, ""))
        );
        if (insertIndex === -1) {
          insertIndex = tableData.length;
        }
      } else {
        insertIndex = tableData.findIndex(
          (row: { value: string }) =>
            Number(row.value.replace(/[^0-9.]/g, "")) <
            Number(avgData.value.replace(/[^0-9.]/g, ""))
        );
        if (insertIndex === -1) {
          insertIndex = tableData.length;
        }
      }
    }

    tableData.splice(insertIndex, 0, avgData);
    setModalTableData(tableData);
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        center
      >
        <div className="header-modal">
          <h3>
            {modalHeadingData.stat +
              " - AVG: " +
              modalHeadingData.avg +
              " - " +
              modalHeadingData.team +
              ": " +
              modalHeadingData.value}
          </h3>
        </div>
        <Table
          columns={modalColumns}
          data={modalTableData}
          teamName={filterSelectedData.selectedTeam?.value}
          forModal={true}
        />
      </Modal>

      <div className="filter-container">
        <div className="filter-head">
          <button
            className="btn sm-btn btn-primary text-normal"
            onClick={() => {
              setIsFiltersVisible(!isFiltersVisible);
            }}
          >
            {isFiltersVisible ? "Hide Filters" : "Show Filters"}
          </button>
        </div>
      </div>
      <div className="spacer mob-hide legacy-h-10"></div>
      <TeamDNAFilters
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setSearchParams={setSearchParams}
        playersListFilters={playersListFilters}
        setPlayersListFilters={setPlayersListFilters}
        setFiltersSelectedData={setFiltersSelectedData}
        filtersSelectedData={filterSelectedData}
        isFiltersVisible={isFiltersVisible}
        tableLoader={tableLoader}
      />
      <div className="common-card">
        <div className="card-head-md legacy-pb-0">
          <figure>
            <img
              src={`https://cdn.nba.com/logos/nba/${filterSelectedData.selectedTeam?.id}/primary/L/logo.svg`}
            />
          </figure>
          <div className="card-head-caption">
            <h4>{filterSelectedData.selectedTeam?.label}</h4>
            <div className="card-head-description">
              {teamWinsLossesLoader ? (
                <span>
                  <Skeleton width={"10rem"} count={1} />
                </span>
              ) : (
                <span>
                  {teamWinsLossesCount?.WINS}-
                  {teamWinsLossesCount?.LOSSES
                    ? Math.abs(teamWinsLossesCount?.LOSSES)
                    : "0"}{" "}
                  (
                  {filterSelectedData.selectedSeasons
                    .map((season) => season.value)
                    .join(", ")}
                  )
                </span>
              )}
              {filterSelectedData.selectedSeasonTypes.map(
                (selectedType, index) => {
                  const foundItem = SEASON_TYPES_LIST.find(
                    (item) => item.value === selectedType
                  );
                  const label = foundItem ? foundItem.label : "";
                  return (
                    <div key={index} className="badge purple">
                      {label}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
        <div className="table-ui">
          <div className="table-ui"></div>
        </div>
      </div>
      <div
        className="legacy-flex--2 legacy-flex--large--1 legacy-flex"
        ref={componentRef}
      >
        <div className="legacy-flex__item">
          <div className="common-card td-pointer">
            <div className="card-head">
              <h4>
                {filterSelectedData.selectedTeam?.value} OFFENSE{" "}
                {filterSelectedData.isPerGameSelected ? "PER GAME" : "PER 100"}
              </h4>
            </div>
            <Table
              columns={columns}
              data={[
                ...selectedTeamData[TableTypes.OFFENSE],
                ...selectedPlayTypesTeamData[TableTypes.OFFENSE],
              ]}
              handleTableRowClick={handleTableRowClick}
              tableType={TableTypes.OFFENSE}
              isDashBoard={true}
              loader={{
                [TeamDnaDataTypes.TEAM_DNA]:
                  tableLoader[TeamDnaDataTypes.TEAM_DNA][TableTypes.OFFENSE],
                [TeamDnaDataTypes.PLAY_TYPES]:
                  tableLoader[TeamDnaDataTypes.PLAY_TYPES][TableTypes.OFFENSE],
              }}
              maxRank={
                filterSelectedData.selectedSeasonTypes.includes(
                  SeasonTypes.REGULAR_SEASON
                ) ||
                filterSelectedData.selectedSeasonTypes.includes(
                  SeasonTypes.PRE_SEASON
                )
                  ? 30
                  : 16
              }
            />
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="common-card td-pointer">
            <div className="card-head">
              <h4>
                {filterSelectedData.selectedTeam?.value} DEFENSE{" "}
                {filterSelectedData.isPerGameSelected ? "PER GAME" : "PER 100"}
              </h4>
            </div>
            <Table
              columns={columns}
              data={[
                ...selectedTeamData[TableTypes.DEFENSE],
                ...selectedPlayTypesTeamData[TableTypes.DEFENSE],
              ]}
              handleTableRowClick={handleTableRowClick}
              tableType={TableTypes.DEFENSE}
              isDashBoard={true}
              loader={{
                [TeamDnaDataTypes.TEAM_DNA]:
                  tableLoader[TeamDnaDataTypes.TEAM_DNA][TableTypes.DEFENSE],
                [TeamDnaDataTypes.PLAY_TYPES]:
                  tableLoader[TeamDnaDataTypes.PLAY_TYPES][TableTypes.DEFENSE],
              }}
              maxRank={
                filterSelectedData.selectedSeasonTypes.includes(
                  SeasonTypes.REGULAR_SEASON
                ) ||
                filterSelectedData.selectedSeasonTypes.includes(
                  SeasonTypes.PRE_SEASON
                )
                  ? 30
                  : 16
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDNA;
