import React from "react";
import { Flex } from "../Flex/Flex";
import { cn } from "../../utils";

export function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("animate-pulse rounded-md bg-muted", className)}
      {...props}
    />
  );
}

export function SkeletonHeading({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <Skeleton className={cn("h-5 w-20", className)} {...props} />;
}

export function SkeletonThumbnail({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Skeleton className={cn("h-16 w-16 rounded-full", className)} {...props} />
  );
}

interface SkeletonTextProps extends React.HTMLAttributes<HTMLDivElement> {
  rows?: number;
}
export function SkeletonText({ rows = 3, ...props }: SkeletonTextProps) {
  return (
    <Flex direction="down" gap="sm" {...props}>
      {[...Array(rows).keys()].map((i) => (
        <Skeleton
          key={`sk-${i}`}
          className={cn("h-3", i == rows - 1 ? "w-3/4" : "w-full")}
        />
      ))}
    </Flex>
  );
}
