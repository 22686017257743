import { FiltersService, Game as RawGame, GameDnaService } from "@/swagger";
import { SeasonTypes } from "@/types/enums/teamDna.enums";
import dayjs from "dayjs";

interface Team {
  id: string;
  abbr: string;
  name: string;
}

export interface Game extends RawGame {
  gameNumber?: number;
}

export async function loader() {
  return {
    games: await getGames(),
    teams: await getTeams(),
  };
}

async function getGames() {
  const { data: gamesData } = await GameDnaService.getGames("2023-24");

  const now = dayjs();
  const { latestGame, upcomingGames, pastGames } = gamesData.reduce<{
    latestGame?: Game;
    upcomingGames: Game[];
    pastGames: Game[];
  }>(
    ({ latestGame, upcomingGames, pastGames }, game) => {
      const gameTime = dayjs(game.gameTimeUtc);

      const gameToAdd: Game = game;
      if (game.seasonType == SeasonTypes.POST_SEASON) {
        gameToAdd.gameNumber = game.homeTeamWins + game.homeTeamLosses;
      }

      if (!game.gameTimeUtc || now.unix() < gameTime.unix()) {
        upcomingGames.push(gameToAdd);
        return { latestGame, upcomingGames, pastGames };
      }

      if (!latestGame) {
        latestGame = gameToAdd;
        return { latestGame, upcomingGames, pastGames };
      }

      pastGames.push(gameToAdd);

      return { latestGame, upcomingGames, pastGames };
    },
    {
      upcomingGames: [],
      pastGames: [],
    }
  );

  return { latestGame, upcomingGames, pastGames };
}

async function getTeams() {
  const { data: conferenceData } = await FiltersService.getTeams();

  const { eastern, western } = conferenceData.reduce<{
    eastern: Team[];
    western: Team[];
  }>(
    ({ western, eastern }, conference) => {
      if (conference.label.includes("Western")) {
        conference.options.forEach((team) => {
          if (team.TEAM_ABBR == "PHX") {
            return;
          }
          western.push({
            id: `${team.TEAM_ID}`,
            abbr: team.TEAM_ABBR,
            name: team.TEAM_FULL_NAME,
          });
        });
      }

      if (conference.label.includes("Eastern")) {
        conference.options.forEach((team) => {
          eastern.push({
            id: `${team.TEAM_ID}`,
            abbr: team.TEAM_ABBR,
            name: team.TEAM_FULL_NAME,
          });
        });
      }

      return { western, eastern };
    },
    { western: [], eastern: [] }
  );

  return { eastern, western };
}
