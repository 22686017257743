export function calcPoss({
  fga,
  fta,
  tov,
  orb,
}: {
  fga: Nullable<number>;
  fta: Nullable<number>;
  tov: Nullable<number>;
  orb: Nullable<number>;
}) {
  if (isNullish(fga, fta, tov, orb)) {
    return null;
  }
  return numberOrNull(round(fga! + 0.44 * fta! - orb! + tov!));
}

export function calcOffRating({
  poss,
  pts,
}: {
  poss: Nullable<number>;
  pts: Nullable<number>;
}) {
  if (isNullish(poss, pts)) {
    return null;
  }

  return numberOrNull(round(100 * (pts! / poss!), 1));
}

export function calcDefRating({
  oppPoss,
  oppPts,
}: {
  oppPoss: Nullable<number>;
  oppPts: Nullable<number>;
}) {
  return calcOffRating({
    poss: oppPoss,
    pts: oppPts,
  });
}

export function calcNetRating({
  offRating,
  defRating,
}: {
  offRating: Nullable<number>;
  defRating: Nullable<number>;
}) {
  if (isNullish(offRating, defRating)) {
    return null;
  }

  return numberOrNull(round(offRating! - defRating!, 3));
}

export function calcEfgPct({
  fgm,
  fga,
  threePts,
}: {
  fgm: Nullable<number>;
  fga: Nullable<number>;
  threePts: Nullable<number>;
}) {
  if (isNullish(fgm, fga, threePts)) {
    return null;
  }

  return numberOrNull(round((fgm! + 0.5 * threePts!) / fga!, 3));
}

export function calcTsPct({
  pts,
  fga,
  fta,
}: {
  pts: Nullable<number>;
  fga: Nullable<number>;
  fta: Nullable<number>;
}) {
  if (isNullish(pts, fga, fta)) {
    return null;
  }

  return numberOrNull(round(pts! / (2 * (fga! + 0.475 * fta!)), 3));
}

export function calcFtRate({
  fga,
  fta,
}: {
  fga: Nullable<number>;
  fta: Nullable<number>;
}) {
  if (isNullish(fga, fta)) {
    return null;
  }

  return numberOrNull(round(fta! / fga!, 3));
}

export function calcOrbPct({
  orb,
  oppDrb,
}: {
  orb: Nullable<number>;
  oppDrb: Nullable<number>;
}) {
  if (isNullish(orb, oppDrb)) {
    return null;
  }

  return numberOrNull(round(orb! / (orb! + oppDrb!), 3));
}

export function calcDrbPct({
  drb,
  oppOrb,
}: {
  drb: Nullable<number>;
  oppOrb: Nullable<number>;
}) {
  if (isNullish(drb, oppOrb)) {
    return null;
  }

  return numberOrNull(round(drb! / (drb! + oppOrb!), 3));
}

export function calcTovPct({
  tov,
  poss,
}: {
  tov: Nullable<number>;
  poss: Nullable<number>;
}) {
  if (isNullish(tov, poss)) {
    return null;
  }

  return numberOrNull(round(tov! / poss!, 3));
}

type Nullable<T> = T | null | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNullish(...items: any[]) {
  return items.findIndex((item) => item == null || item == undefined) >= 0;
}

function round(num: number, decimals: number = 0) {
  const magnitude = +"1".padEnd(decimals + 1, "0");
  return Math.round(num * magnitude) / magnitude;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function numberOrNull(input: any) {
  return typeof input == "number" ? input : null;
}
