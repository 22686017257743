import { GameHero, TeamLogo } from "@/components";
import { StatName } from "@/shared/const";
import { useAsync, useInterval } from "@/shared/hooks";
import { getLeagueIdFromGameId } from "@/shared/utils";
import {
  Alert,
  Button,
  Card,
  Flex,
  Grid,
  Skeleton,
  SkeletonHeading,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from "@suns/design-system";
import { Info } from "@suns/design-system/icons";
import { useParams } from "react-router-dom";
import { boxScoreLoader } from "./loaders/boxscore-loader";
import { GameHeroSkeleton } from "@/components/GameHero/GameHero";

export function GameDnaLive() {
  const { gameId } = useParams();
  const boxScoreStream = useAsync(boxScoreLoader, {
    gameId: gameId || "",
  });
  useInterval(async () => {
    if (
      boxScoreStream.loading ||
      boxScoreStream.response?.game.gameStatus !== 2
    ) {
      return;
    }
    boxScoreStream.refresh();
  }, 10000);

  if (boxScoreStream.loading && !boxScoreStream.response) {
    return <GameDnaLiveLoading />;
  }

  if (!boxScoreStream.response || !gameId) {
    if (boxScoreStream.error) {
      throw boxScoreStream.error;
    }
    throw Error("There was an error retrieving the box score stream.");
  }

  const { game, team, oppTeam, homeTeam, awayTeam } = boxScoreStream.response;

  const fourFactorTable = [
    [
      percent(awayTeam?.advancedBox.efgPct) || "--",
      "eFG%",
      percent(homeTeam?.advancedBox.efgPct) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.tovPct) || "--",
      "TOV%",
      percent(homeTeam?.advancedBox.tovPct) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.orbPct) || "--",
      "ORB%",
      percent(homeTeam?.advancedBox.orbPct) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.ftaRate) || "--",
      "FTA Rate",
      percent(homeTeam?.advancedBox.ftaRate) || "--",
    ],
  ] satisfies [string | number, StatName, string | number][];

  const advancedBoxTable = [
    [
      "ORtg",
      team?.advancedBox.offRating || "--",
      team?.season?.offRating || "--",
    ],
    [
      "DRtg",
      team?.advancedBox.defRating || "--",
      team?.season?.defRating || "--",
    ],
    [
      "NRtg",
      team?.advancedBox.netRating || "--",
      team?.season?.netRating || "--",
    ],
    [
      "eFG%",
      percent(team?.advancedBox.efgPct) || "--",
      percent(team?.season?.efgPct) || "--",
    ],
    [
      "Opp. eFG%",
      percent(oppTeam?.advancedBox.efgPct) || "--",
      percent(team?.season?.oppEfgPct) || "--",
    ],
    [
      "TOV%",
      percent(team?.advancedBox.tovPct) || "--",
      percent(team?.season?.tmTovPct) || "--",
    ],
    [
      "Opp. TOV%",
      percent(oppTeam?.advancedBox.tovPct) || "--",
      percent(team?.season?.oppTovPct) || "--",
    ],
    [
      "ORB%",
      percent(team?.advancedBox.orbPct) || "--",
      percent(team?.season?.orebPct) || "--",
    ],
    [
      "DRB%",
      percent(team?.advancedBox.drbPct) || "--",
      percent(team?.season?.drebPct) || "--",
    ],
    [
      "PITP",
      team?.boxScore.pointsInThePaint || 0,
      team?.season?.ptsPaint || "--",
    ],
    [
      "Opp. PITP",
      oppTeam?.boxScore.pointsInThePaint || 0,
      team?.season?.oppPtsPaint || "--",
    ],
  ] satisfies [StatName, number | string, number | string][];

  return (
    <>
      <>
        <Card className="mb-4 overflow-hidden">
          <div className="absolute -bottom-16 -right-16 hidden w-1/3 max-w-[400px] lg:block">
            <div className="absolute h-full w-full bg-gradient-to-r from-white to-white/75" />
            <TeamLogo
              teamId={game.homeTeam!.teamId!}
              className="w-full"
              leagueId={getLeagueIdFromGameId(gameId)}
            />
          </div>
          <div className="absolute -bottom-16 -left-16 hidden w-1/3 max-w-[400px] lg:block">
            <div className="absolute h-full w-full bg-gradient-to-l from-white to-white/75" />
            <TeamLogo
              teamId={game.awayTeam!.teamId!}
              className="w-full"
              leagueId={getLeagueIdFromGameId(gameId)}
            />
          </div>
          <GameHero
            gameId={gameId}
            gameStatus={game.gameStatus || 1}
            gameTimeUtc={game.gameTimeUTC!}
            gameClock={game.gameClock!}
            period={game.period!}
            homeTeam={{
              id: game.homeTeam!.teamId!,
              tricode: game.homeTeam!.teamTricode!,
              score: game.homeTeam!.score!,
            }}
            awayTeam={{
              id: game.awayTeam!.teamId!,
              tricode: game.awayTeam!.teamTricode!,
              score: game.awayTeam!.score!,
            }}
          />
          <Table className="mx-auto mt-3 max-w-[600px]">
            <TableCaption>
              This table displays the Four Factors, a proven identifier for
              winning games.
              <Button size="xs" variant="outline" className="ml-1" asChild>
                <a
                  href="https://www.basketball-reference.com/about/factors.html"
                  target="_blank"
                >
                  Learn more.
                </a>
              </Button>
            </TableCaption>
            <TableBody>
              {fourFactorTable.map(([away, stat, home], idx) => (
                <TableRow
                  key={`four-factors-${idx}`}
                  className="text-md"
                  centerAll
                >
                  <TableCell>{away}</TableCell>
                  <TableCell className="w-24 font-bold">{stat}</TableCell>
                  <TableCell>{home}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>

        {game.gameStatus !== 1 && (
          <Grid gap="md" columns="lg:2" align="start">
            <Card>
              <Text size="xl" heading className="mb-2">
                Advanced Box
              </Text>
              <Table>
                <TableHeader>
                  <TableRow>
                    {["Stat", "Value", "Team Avg."].map((heading, idx) => (
                      <TableHead key={`advanced-box-heading-${idx}`}>
                        {heading}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {advancedBoxTable.map(([stat, value, avg], idx) => (
                    <TableRow key={`advanced-boxscore-${idx}`}>
                      <TableCell>{stat}</TableCell>
                      <TableCell>{value}</TableCell>
                      <TableCell>{avg}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
            <Flex direction="down" gap="md">
              <Card>
                <Text size="xl" heading className="mb-2">
                  Crashers
                </Text>
                <NoTrackingAlert />
              </Card>

              <Card>
                <Text size="xl" heading className="mb-2">
                  Shooting
                </Text>
                <NoTrackingAlert />
              </Card>

              <Card>
                <Text size="xl" heading className="mb-2">
                  Play types
                </Text>
                <NoTrackingAlert />
              </Card>
            </Flex>
          </Grid>
        )}
      </>
    </>
  );
}

function GameDnaLiveLoading() {
  return (
    <>
      <Card className="mb-4">
        <GameHeroSkeleton />
        <Table className="mx-auto mt-3 max-w-[600px]">
          <TableBody>
            {[1, 2, 3, 4].map((idx) => (
              <TableRow key={`loading-${idx}`} className="text-md" centerAll>
                <TableCell>
                  <SkeletonHeading className="mx-auto" />
                </TableCell>
                <TableCell className="w-24 font-bold">
                  <SkeletonHeading className="mx-auto" />
                </TableCell>
                <TableCell>
                  <SkeletonHeading className="mx-auto" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <Grid gap="md" columns="2" align="start">
        <Card>
          <Skeleton className="h-[60px] w-full" />
        </Card>
        <Card>
          <Skeleton className="h-[60px] w-full" />
        </Card>
      </Grid>
    </>
  );
}

const NoTrackingAlert = () => (
  <Alert
    title="Coming soon"
    description="We're currently working on integrating real-time data from Second Spectrum."
    variant="info"
    Icon={Info}
  />
);

function percent(input: number | null | undefined) {
  if (input == undefined || input == null) {
    return null;
  }
  const percent = Math.round(input * 1000) / 10;
  return `${percent}%`;
}
