import { useEffect, useState } from "react";

interface Return<T> {
  loading: boolean;
  response: T | null;
  error: Error | null;
  refresh: () => void;
}

export function useAsync<T>(fn: () => Promise<T>): Return<T>;
export function useAsync<T, P>(
  fn: (args: P) => Promise<T>,
  params: P
): Return<T>;
export function useAsync<T, P>(
  fn: (args?: P) => Promise<T>,
  params?: P
): Return<T> {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => run(), []);

  function run() {
    fn(params)
      .then((response) => {
        setResponse(response);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }

  function refresh() {
    setLoading(true);
    run();
  }

  return {
    loading,
    response,
    error,
    refresh,
  };
}
