import { useEffect, useState } from "react";
import { logout } from "@/shared/auth";
import { NavLink, useNavigate } from "react-router-dom";
import logoMain from "@/assets/img/light-logo.svg";
import { URL } from "@/shared/utils/route-constant";
import {
  Accordion,
  Button,
  Card,
  Flex,
  Grid,
  Text,
  cn,
} from "@suns/design-system";
import {
  Dribbble,
  FlaskConical,
  Home,
  LibraryBig,
  LogOut,
  Menu,
  Play,
  Users,
  X,
  Pencil,
} from "@suns/design-system/icons";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@suns/design-system/src/components/Accordion/Accordion";
import { useAccount } from "@/shared/hooks";
interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  end?: boolean;
  className?: string;
  setIsMenuOpen: (isOpen: boolean) => void;
}

const CustomNavLink = ({ to, children, end, setIsMenuOpen }: NavLinkProps) => {
  return (
    <NavLink
      className={({ isActive, isPending }) =>
        cn(
          "ml-10 text-sm text-gray-200 hover:text-primary",
          isActive && "font-semibold text-primary",
          isPending && "text-primary"
        )
      }
      to={to}
      end={end}
      onClick={() => {
        if (window.innerWidth < 1024) {
          setIsMenuOpen(false);
        }
      }}
    >
      {children}
    </NavLink>
  );
};

export default function SideBar() {
  const navigate = useNavigate();

  const { hasBetaScoutingAccess, hasBetaShinyAccess } = useAccount();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const homeHandler = () => {
    navigate("/");
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isMenuOpen]);

  return (
    <>
      <Card className="fixed z-10 h-16 w-full rounded-none border-0 bg-black lg:hidden">
        <Flex justify="between" align="center">
          <NavLink to={URL.TEAM.path}>
            <Flex direction="right" align="center">
              <img className="h-8 pr-3" src={logoMain} />
              <Text heading className="text-white">
                Phoenix Suns
              </Text>
            </Flex>
          </NavLink>
          <Menu onClick={toggleMenu} className="size-8 stroke-2 text-white" />
        </Flex>
      </Card>
      {isMenuOpen && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 lg:hidden">
          {/* This overlay div will cover the entire screen in mobile view when the menu is open */}
        </div>
      )}

      <Card
        className={cn(
          `fixed right-0 z-20 h-full w-52 transform rounded-none border-0 bg-black
          transition-transform lg:left-0 lg:block`,
          isMenuOpen ? "translate-x-0" : "translate-x-full lg:translate-x-0"
        )}
      >
        <Flex direction="down" justify="between" className="h-full">
          <div>
            <Flex justify="end" className="lg:hidden ">
              <X
                onClick={toggleMenu}
                color="white"
                className="mb-4 size-6 stroke-2"
              />
            </Flex>
            <Flex
              direction="down"
              align="center"
              className=" my-4 xs:max-lg:hidden"
            >
              <NavLink to={"/"}>
                <img src={logoMain} />
              </NavLink>
            </Flex>
            <Button
              onClick={homeHandler}
              variant="ghost"
              className="h-12 w-full text-sm font-medium text-white hover:bg-purple-800 hover:font-bold
                hover:text-white"
            >
              <Flex align="center" justify="start" className="w-full">
                <Home className="mr-2" size={18} color="white" />
                Home
              </Flex>
            </Button>
            <Accordion type="multiple">
              <AccordionItem value="item-1">
                <AccordionTrigger className="group">
                  <Flex align="center">
                    <Users size={18} />
                    <Text size="sm" className="ml-2">
                      Players
                    </Text>
                  </Flex>
                </AccordionTrigger>
                <AccordionContent>
                  <Grid gap="md" className="mt-1">
                    <CustomNavLink
                      to={URL.DASHBOARD.children.BOX_SCORES.path}
                      end
                      setIsMenuOpen={setIsMenuOpen}
                    >
                      Boxscore
                    </CustomNavLink>
                    <CustomNavLink
                      to={`${URL.DASHBOARD.children.BOX_SCORES.path}/${URL.DASHBOARD.children.BOX_SCORES.children.LINEUPS.path}`}
                      end
                      setIsMenuOpen={setIsMenuOpen}
                    >
                      Lineups
                    </CustomNavLink>
                    <CustomNavLink
                      to={`${URL.DASHBOARD.children.BOX_SCORES.path}/${URL.DASHBOARD.children.BOX_SCORES.children.REBOUNDING.path}`}
                      end
                      setIsMenuOpen={setIsMenuOpen}
                    >
                      Rebounding
                    </CustomNavLink>
                    <a
                      className="ml-10 text-sm text-gray-200 hover:text-primary"
                      href={"https://personnel.phxapollo.com/"}
                      target="_blank"
                    >
                      Personnel
                    </a>
                  </Grid>
                </AccordionContent>
              </AccordionItem>
              {hasBetaScoutingAccess ? (
                <AccordionItem value="item-2">
                  <AccordionTrigger className="group">
                    <Flex align="center">
                      <Pencil size={18} />
                      <Text size="sm" className="ml-2">
                        Reports
                      </Text>
                    </Flex>
                  </AccordionTrigger>
                  <AccordionContent>
                    <Grid gap="md" className="mt-1">
                      <CustomNavLink
                        to={`${URL.REPORTS.path}`}
                        end
                        setIsMenuOpen={setIsMenuOpen}
                      >
                        My Reports
                      </CustomNavLink>
                      <CustomNavLink
                        to={`${URL.REPORTS.path}/${URL.REPORTS.children.CREATE_REPORT.path}`}
                        end
                        setIsMenuOpen={setIsMenuOpen}
                      >
                        Create Report
                      </CustomNavLink>
                    </Grid>
                  </AccordionContent>
                </AccordionItem>
              ) : null}

              <AccordionItem value="item-3">
                <AccordionTrigger className="group">
                  <Flex align="center">
                    <Play size={18} />
                    <Text size="sm" className="ml-2">
                      Team
                    </Text>
                  </Flex>
                </AccordionTrigger>
                <AccordionContent>
                  <Grid gap="md" className="mt-1">
                    <CustomNavLink
                      to={URL.TEAM.path}
                      end
                      setIsMenuOpen={setIsMenuOpen}
                    >
                      Team DNA
                    </CustomNavLink>
                  </Grid>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-4">
                <AccordionTrigger className="group">
                  <Flex align="center">
                    <Dribbble size={18} />
                    <Text size="sm" className="ml-2">
                      Games
                    </Text>
                  </Flex>
                </AccordionTrigger>
                <AccordionContent>
                  <Grid gap="md" className="p mt-1">
                    <CustomNavLink
                      to={`${URL.GAME_DNA.path}/latest`}
                      end
                      setIsMenuOpen={setIsMenuOpen}
                    >
                      Latest Games
                    </CustomNavLink>
                    <CustomNavLink
                      to={URL.GAME_DNA.path}
                      end
                      setIsMenuOpen={setIsMenuOpen}
                    >
                      All Games
                    </CustomNavLink>
                  </Grid>
                </AccordionContent>
              </AccordionItem>
              {hasBetaShinyAccess ? (
                <AccordionItem value="item-5">
                  <AccordionTrigger className="group">
                    <Flex align="center">
                      <FlaskConical size={18} />
                      <Text size="sm" className="ml-2">
                        Labs
                      </Text>
                    </Flex>
                  </AccordionTrigger>
                  <AccordionContent>
                    <Grid gap="md" className="mt-1">
                      <CustomNavLink
                        to={URL.LABS.path}
                        end
                        setIsMenuOpen={setIsMenuOpen}
                      >
                        Apps
                      </CustomNavLink>
                    </Grid>
                  </AccordionContent>
                </AccordionItem>
              ) : null}
            </Accordion>
          </div>
          <Flex justify="end" direction="down" className="pl-6">
            <NavLink
              to={URL.GLOSSARY.path}
              className={({ isActive }) =>
                isActive
                  ? "text-medium text-xs font-semibold text-primary"
                  : "text-xs font-medium text-gray-400 "
              }
              end
              onClick={() => {
                if (window.innerWidth < 1024) {
                  setIsMenuOpen(false);
                }
              }}
            >
              <Flex align="center" className="mb-4 cursor-pointer">
                <LibraryBig
                  className={cn(
                    "size-5",
                    location.pathname === "/glossary" && "text-primary"
                  )}
                />
                <Text size="sm" className="pl-2 ">
                  Glossary
                </Text>
              </Flex>
            </NavLink>
            <NavLink to={URL.AUTH.children.LOGIN.path}>
              <Flex onClick={logout} align="center" className="cursor-pointer">
                <LogOut className="size-5 text-gray-200" />
                <Text size="sm" className="pl-2 font-medium text-gray-200">
                  Logout
                </Text>
              </Flex>
            </NavLink>
          </Flex>
        </Flex>{" "}
      </Card>
    </>
  );
}
