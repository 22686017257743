export const URL = {
  AUTH: {
    children: {
      LOGIN: { path: "login", title: "Login" },
      SSO_AUTHORIZE: { path: "sso-authorize", title: "SSO Authorize" },
    },
  },
  DASHBOARD: {
    children: {
      BOX_SCORES: {
        path: "box-scores",
        title: "Box Scores",
        children: {
          LINEUPS: { path: "lineups", title: "Lineups" },
          REBOUNDING: { path: "rebounding", title: "Rebounding" },
        },
      },
    },
  },
  REPORTS: {
    path: "reports",
    title: "Reports",
    children: {
      MY_REPORTS: { path: "my-reports", title: "My Reports" },
      CREATE_REPORT: { path: "create", title: "Create Report" },
    },
  },
  TEAM: {
    path: "team",
    title: "Team DNA",
    children: {
      SHOOTING_TABLE: { path: "shooting-table", title: "Shooting Table" },
    },
  },
  GAME_DNA: {
    path: "game",
    title: "Game DNA",
    children: {
      CHANGE_GAME: { path: "change-game", title: "Change Game" },
    },
  },
  LABS: {
    path: "labs",
    title: "Labs",
  },
  GLOSSARY: {
    path: "glossary",
    title: "Glossary",
  },
};
