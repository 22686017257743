import Bugsnag, { NotifiableError } from "@bugsnag/js";
import { isBugsnagConfigured } from "./start-bugsnag";

export function notify(error: NotifiableError) {
  if (isBugsnagConfigured()) {
    Bugsnag.notify(error);
  } else {
    console.error(error);
  }
}
