import { loginRequest } from "@/msal.config";
import { msalInstance } from "./instance";
import { getAccount } from "./account";

/*
 * Tokens are only valid for 60m. We cache them for 5m. This is mostly to avoid
 * aquiring a new token when there's multiple API calls on a single page load.
 *
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
 */
let cachedToken: {
  value: string;
  timestamp: number;
} | null = null;

const tokenLifetime = 1000 * 60 * 5;

export async function getAuthToken(): Promise<string | null> {
  if (cachedToken) {
    const isCacheInvalid = Date.now() - cachedToken.timestamp > tokenLifetime;
    if (!isCacheInvalid) {
      return cachedToken.value;
    }
  }

  const { isAuthenticated, info } = getAccount();
  if (!isAuthenticated) {
    return null;
  }

  try {
    const silentRequest = {
      ...loginRequest,
      info,
    };

    const { idToken } = await msalInstance.acquireTokenSilent(silentRequest);
    if (!idToken) {
      return null;
    }
    cachedToken = {
      value: idToken,
      timestamp: Date.now(),
    };
    return idToken;
  } catch {
    return null;
  }
}

export function clearAuthTokenCache() {
  cachedToken = null;
}
