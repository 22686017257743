import { SunsApi } from "@suns/api";
import { getAuthToken } from "./auth";

export const { getGame, getTeam, getTeams, getSchedule } = new SunsApi({
  apiKeys: {
    // TODO: Hide these behind our Apollo API.
    // These api endpoints don't offer sensitive data so it's not critical.
    querytool: "2fd30cba-59f6-4517-aa06-a8baf60b1f01",
    scores: "5d2d15a1-2116-4fdb-bea1-a4a048c7eaea",
    stats: "5327871a-8cb1-4ccf-a8dc-48538bdada3f",
    schedule: "a9035ecf-ac24-48d8-b48d-750f46f2bf47",
  },
  apolloUrl: import.meta.env.VITE_API_URL,
  nbaUrl: `${import.meta.env.VITE_API_URL}/nba-api`,
  getToken: async () => {
    const token = (await getAuthToken()) as string;
    return token;
  },
});
