import { LeagueId } from "@suns/api";
import {
  cn,
  classVariants,
  type VariantParams,
  responsiveVariants,
} from "@suns/design-system/src/utils";

const sizes = {
  xs: "w-10",
  sm: "w-12",
  md: "w-16",
  lg: "w-24",
};

const teamLogoVariants = classVariants([], {
  variants: {
    size: {
      ...sizes,
      ...responsiveVariants("sm", sizes),
      ...responsiveVariants("md", sizes),
      ...responsiveVariants("lg", sizes),
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type TeamLogoProps = React.HTMLAttributes<HTMLDivElement> &
  VariantParams<typeof teamLogoVariants> & {
    teamId: number;
    leagueId?: LeagueId;
  };

export function TeamLogo({
  teamId,
  leagueId,
  size,
  className,
  ...props
}: TeamLogoProps) {
  let src: string;
  if (leagueId == LeagueId.Wnba) {
    src = `https://cdn.wnba.com/logos/wnba/${teamId}/primary/L/logo.svg`;
  } else if (leagueId == LeagueId.GLeague) {
    src = `https://ak-static.cms.nba.com/wp-content/uploads/logos/nbagleague/${teamId}/primary/L/logo.svg`;
  } else {
    src = `https://cdn.nba.com/logos/nba/${teamId}/primary/L/logo.svg`;
  }
  return (
    <div className={cn(teamLogoVariants({ size }), className)} {...props}>
      <img src={src} className="w-full" />
    </div>
  );
}
