export type StatName = (typeof glossaryList)[number]["stat"];

export const glossaryList = [
  { id: 1, stat: "2P", definition: "2-Point Field Goals" },
  {
    id: 2,
    stat: "2P%",
    definition: "2-Point Field Goal Percentage; the formula is 2P / 2PA.",
  },
  { id: 3, stat: "2PA", definition: "2-Point Field Goal Attempts" },
  {
    id: 4,
    stat: "3P",
    definition: "3-Point Field Goals",
  },
  {
    id: 5,
    stat: "3P%",
    definition: "3-Point Field Goal Percentage; the formula is 3P / 3PA.",
  },
  {
    id: 6,
    stat: "3PA",
    definition: "3-Point Field Goal Attempts",
  },
  { id: 7, stat: "AST", definition: "Assists" },
  {
    id: 8,
    stat: "AST%",
    definition:
      "Assist Percentage; the formula is 100 * AST / (((MP / (Tm MP / 5)) * Tm FG) - FG). Assist percentage is an estimate of the percentage of teammate field goals a player assisted while he was on the floor.",
  },
  { id: 9, stat: "BLK", definition: "Blocks" },
  {
    id: 10,
    stat: "BLK%",
    definition:
      "Block Percentage; the formula is 100 * (BLK * (Tm MP / 5)) / (MP * (Opp FGA - Opp 3PA)). Block percentage estimates the percentage of the opponent's two-point field goal attempts blocked by the player while he was on the floor.",
  },
  {
    id: 11,
    stat: "DRB",
    definition: "Defensive Rebounds",
  },
  {
    id: 12,
    stat: "DRB%",
    definition:
      "Defensive Rebound Percentage; the formula is 100 * (DRB * (Tm MP / 5)) / (MP * (Tm DRB + Opp ORB)). Defensive rebound percentage estimates the percentage of available defensive rebounds a player grabbed while he was on the floor.",
  },
  {
    id: 13,
    stat: "DRtg",
    definition:
      "Defensive Rating; for players and teams, it is points allowed per 100 possessions. Dean Oliver, author of Basketball on Paper, developed this rating. Please see the article Calculating Individual Offensive and Defensive Ratings for more information.",
  },
  {
    id: 14,
    stat: "eFG%",
    definition:
      "Effective Field Goal Percentage; the formula is (FG + 0.5 * 3P) / FGA. This statistic adjusts for a 3-point field goal worth one more point than a 2-point field goal. For example, suppose Player A goes 4 for 10 with 2 threes, while Player B goes 5 for 10 with 0 threes. Each player would have 10 points from field goals and thus would have the same effective field goal percentage (50%).",
  },
  {
    id: 15,
    stat: "FG",
    definition:
      "Field Goals (includes both 2-point field goals and 3-point field goals)",
  },
  {
    id: 16,
    stat: "FG%",
    definition: "Field Goal Percentage; the formula is FG / FGA.",
  },
  {
    id: 17,
    stat: "FGA",
    definition:
      "Field Goal Attempts (includes both 2-point field goal attempts and 3-point field goal attempts)",
  },
  { id: 18, stat: "FT", definition: "Free Throws" },
  {
    id: 19,
    stat: "FT%",
    definition: "Free Throw Percentage; the formula is FT / FTA.",
  },
  { id: 20, stat: "FTA", definition: "Free Throw Attempts" },
  { id: 21, stat: "L", definition: "Losses" },
  { id: 22, stat: "MP", definition: "Minutes Played" },
  {
    id: 23,
    stat: "ORtg",
    definition:
      "Offensive Rating; for players, it is points produced per 100 possessions, while for teams, it is points scored per 100 possessions. This rating was developed by Dean Oliver, author of Basketball on Paper. Please see the article Calculating Individual Offensive and Defensive Ratings for more information.",
  },
  { id: 24, stat: "Opp", definition: "Opponent" },
  { id: 25, stat: "ORB", definition: "Offensive Rebounds" },
  {
    id: 26,
    stat: "ORB%",
    definition:
      "Offensive Rebound Percentage; the formula is 100 * (ORB * (Tm MP / 5)) / (MP * (Tm ORB + Opp DRB)). Offensive rebound percentage estimates the percentage of available offensive rebounds a player grabbed while he was on the floor.",
  },
  {
    id: 27,
    stat: "Pace",
    definition:
      "Pace Factor; the formula is 48 * ((Tm Poss + Opp Poss) / (2 * (Tm MP / 5))). The pace factor estimates a team's total number of possessions per 48 minutes. (Note: 40 minutes is used in the calculation for the WNBA.)",
  },
  {
    id: 28,
    stat: "Per Game",
    definition: "A statistic (e.g., assists) divided by games.",
  },
  { id: 29, stat: "PF", definition: "Personal Fouls" },
  {
    id: 30,
    stat: "Poss",
    definition:
      "Possessions; the formula for teams is 0.5 * ((Tm FGA + 0.4 * Tm FTA - 1.07 * (Tm ORB / (Tm ORB + Opp DRB)) * (Tm FGA - Tm FG) + Tm TOV) + (Opp FGA + 0.4 * Opp FTA - 1.07 * (Opp ORB / (Opp ORB + Tm DRB)) * (Opp FGA - Opp FG) + Opp TOV)). This formula estimates possessions based on both the team's statistics and their opponent's statistics, then averages them to provide a more stable estimate. Please see the article Calculating Individual Offensive and Defensive Ratings for more information.",
  },
  { id: 31, stat: "PTS", definition: "Points" },
  { id: 32, stat: "STL", definition: "Steals" },
  {
    id: 33,
    stat: "STL%",
    definition:
      "Steal Percentage; the formula is 100 * (STL * (Tm MP / 5)) / (MP * Opp Poss). Steal Percentage is an estimate of the percentage of opponent possessions that end with a steal by the player while he is on the floor.",
  },
  { id: 34, stat: "TOV", definition: "Turnovers" },
  {
    id: 35,
    stat: "TOV%",
    definition:
      "Turnover Percentage; the formula is 100 * TOV / (FGA + 0.44 * FTA + TOV). The turnover percentage is an estimate of turnovers per 100 plays.",
  },
  {
    id: 36,
    stat: "TRB%",
    definition:
      "Total Rebound Percentage; the formula is 100 * (TRB * (Tm MP / 5)) / (MP * (Tm TRB + Opp TRB)). Total rebound percentage is an estimate of the percentage of available rebounds a player grabbed while he was on the floor.",
  },
  {
    id: 37,
    stat: "Usg%",
    definition:
      "Usage Percentage; the formula is 100 * ((FGA + 0.44 * FTA + TOV) * (Tm MP / 5)) / (MP * (Tm FGA + 0.44 * Tm FTA + Tm TOV)). Usage percentage is an estimate of the percentage of team plays used by a player while he was on the floor.",
  },
  { id: 38, stat: "W", definition: "Wins" },
  { id: 39, stat: "+/-", definition: "Plus/Minus" },
  {
    id: 40,
    stat: "Crash%",
    definition: "Percentage of Rebounds on Offensive Crashes",
  },
  {
    id: 41,
    stat: "DRB% Diff ON",
    definition:
      "Difference in Team's Defensive Rebound Percentage when Player is On Court",
  },
  { id: 42, stat: "DREB", definition: "Defensive Rebounds" },
  { id: 43, stat: "DRIVE EFFICIENCY", definition: "Drive Efficiency" },
  { id: 44, stat: "FGM", definition: "Field Goals Made" },
  { id: 45, stat: "GP", definition: "Games Played" },
  { id: 46, stat: "ISO EFFICIENCY", definition: "Isolation Efficiency" },
  { id: 47, stat: "Min", definition: "Minutes Per Game" },
  { id: 48, stat: "NRtg", definition: "Net Rating" },
  {
    id: 49,
    stat: "OFFBALL SCREEN EFFICIENCY",
    definition: "Off-ball Screen Efficiency",
  },
  {
    id: 50,
    stat: "Opp. eFG%",
    definition: "Opponent's Effective Field Goal Percentage",
  },
  {
    id: 51,
    stat: "Opp. FTA Rate",
    definition: "Opponent's Free Throw Attempt Rate",
  },
  {
    id: 52,
    stat: "Opp. PLAYER ADJUSTED SHOT QUALITY",
    definition: "Opponent's Player Adjusted Shot Quality",
  },
  {
    id: 53,
    stat: "Opp. Rim FG%",
    definition: "Opponent's Field Goal Percentage at the Rim",
  },
  {
    id: 54,
    stat: "Opp. Rim Rate",
    definition: "Opponent's Rate of Shots at the Rim",
  },
  { id: 55, stat: "Opp. SHOT QUALITY", definition: "Opponent's Shot Quality" },
  {
    id: 56,
    stat: "ORB% Diff ON",
    definition:
      "Difference in Team's Offensive Rebound Percentage when Player is On Court",
  },
  { id: 57, stat: "PACE", definition: "Pace of the game" },
  { id: 58, stat: "P&R EFFICIENCY", definition: "Pick and Roll Efficiency" },
  { id: 59, stat: "PITP ALLOWED", definition: "Points in the Paint Allowed" },
  { id: 60, stat: "POST UP EFFICIENCY", definition: "Post Up Efficiency" },
  { id: 61, stat: "PPP", definition: "Points Per Possession" },
  { id: 62, stat: "REB", definition: "Total Rebounds" },
  { id: 63, stat: "TRANSITION PTS", definition: "Points scored in transition" },
  {
    id: 64,
    stat: "TRANSITION PTS ALLOWED",
    definition: "Transition Points Allowed",
  },
  {
    id: 65,
    stat: "TURNOVERS FORCED RATE",
    definition: "Rate at which turnovers are forced",
  },
  { id: 66, stat: "XPPP", definition: "Expected Points Per Possession" },
  { id: 67, stat: "FTA Rate", definition: "Free Throw Attempt Rate" },
  { id: 68, stat: "3PM", definition: "Three Point Field Goals Made" },
  { id: 69, stat: "PITP", definition: "Points in the Paint" },
  {
    id: 70,
    stat: "AVG NO. PLAYERS CRASHING",
    definition: "Average number of players crashing",
  },
  { id: 71, stat: "P&R VOLUME", definition: "Pick and Roll Volume" },
  { id: 72, stat: "ISO VOLUME", definition: "Isolation Volume" },
  { id: 73, stat: "DHO VOLUME", definition: "Dribble Handoff Volume" },
  { id: 74, stat: "POST UP VOLUME", definition: "Post Up Volume" },
  {
    id: 75,
    stat: "OFFBALL SCREEN VOLUME",
    definition: "Off-ball Screen Volume",
  },
  { id: 76, stat: "DRIVE VOLUME", definition: "Drive Volume" },
  { id: 77, stat: "DEFLECTIONS", definition: "Deflection" },
  { id: 78, stat: "FOULS COMMITTED", definition: "Amount of fouls committed" },
  { id: 79, stat: "CHARGES DRAWN", definition: "Amount of charges drawn" },
  {
    id: 80,
    stat: "Opp. TRANSITION PTS",
    definition: "Opponent's transition points",
  },
  { id: 81, stat: "Opp. TOV%", definition: "Opponent's turnovers rate" },
  { id: 82, stat: "Opp. PITP", definition: "Opponent's points in the paint" },
];
