import { Button, Card, Grid } from "@suns/design-system";

interface ReportTypeSelectionProps {
  handleReportTypeChange: (reportType: string) => void;
  selectedReportType: string;
}

export default function ReportTypeSelection({
  handleReportTypeChange,
  selectedReportType,
}: ReportTypeSelectionProps) {
  return (
    <Card className="mb-4">
      <Grid gap="md" columns="4" className="xs:max-md:grid-cols-2">
        {["Game", "Phase", "Multi-Game", "Other"].map((item) => (
          <Button
            key={item}
            variant="outline"
            className={`h-10 w-full rounded-md px-8 text-sm font-semibold text-gray-600
            hover:bg-gray-100 lg:h-20 lg:text-base ${
              selectedReportType === item
                ? "border-2 border-black bg-gray-100 text-black"
                : ""
            }`}
            onClick={() => handleReportTypeChange(item)}
          >
            {item}
          </Button>
        ))}
      </Grid>
    </Card>
  );
}
