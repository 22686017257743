import { useAccount } from "@/shared/hooks";
import { Navigate, useLocation } from "react-router-dom";

interface ProtectedProps {
  children: React.ReactNode;
}
export function Protected({ children }: ProtectedProps) {
  const { isAuthenticated, hasDefaultAccess } = useAccount();
  const { pathname } = useLocation();
  return isAuthenticated && hasDefaultAccess ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: pathname }} replace />
  );
}

export default Protected;
