import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Flex, Text } from "@suns/design-system";
import dayjs from "dayjs";

import { Game } from "../../game-dna-listings-loader";
import { TeamLogo } from "@/components";

export function GameCard({ ...props }: Game) {
  const {
    gameId,
    homeTeamId,
    homeTeamAbbr,
    homeTeamScore,
    awayTeamId,
    awayTeamAbbr,
    awayTeamScore,
    gameDate,
    gameWinnerTeamAbbr,
    gameNumber,
  } = props;
  const date = useMemo(() => dayjs(gameDate), [gameDate]);

  return (
    <Link to={gameId}>
      <Flex>
        <Flex
          direction="down"
          className="mr-4 w-52 flex-1 border-r-2 border-gray-300 pr-4"
        >
          <GameCardTeam
            id={awayTeamId}
            abbr={awayTeamAbbr}
            score={awayTeamScore}
            isWinner={awayTeamAbbr == gameWinnerTeamAbbr}
          />
          <GameCardTeam
            id={homeTeamId}
            abbr={homeTeamAbbr}
            score={homeTeamScore}
            isWinner={homeTeamAbbr == gameWinnerTeamAbbr}
          />
        </Flex>

        <Flex
          direction="down"
          justify="between"
          align="start"
          className="w-1/3 py-2"
        >
          {gameNumber ? <Text heading>Game {gameNumber}</Text> : null}
          <Text>{date.format("MMMM D, YYYY")}</Text>
          <Button variant="link" size="flush">
            Game DNA
          </Button>
        </Flex>
      </Flex>
    </Link>
  );
}

function GameCardTeam({
  id,
  abbr,
  score,
  isWinner,
}: {
  id: number;
  abbr: string;
  score: number;
  isWinner: boolean;
}) {
  const scoreClass = useMemo(() => {
    if (abbr != "PHX") {
      return "";
    }

    return isWinner ? "text-green-600" : "text-red-600";
  }, [abbr, isWinner]);

  return (
    <Flex align="center" gap="sm" justify="between" direction="right">
      <TeamLogo teamId={id} size="sm" />
      <Text size="2xl" heading>
        {abbr}
      </Text>
      <Text size="2xl" className={`ml-auto ${scoreClass}`}>
        {score}
      </Text>
    </Flex>
  );
}
