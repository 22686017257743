export { labsList } from "./labs";
export { glossaryList, type StatName } from "./glossary";

export const Suns = {
  id: 1610612756,
  abbr: "PHX",
  fullName: "Phoenix Suns",
};

export const Mercury = {
  id: 1611661317,
  abbr: "PHO",
  fullName: "Phoenix Mercury",
};
