import { MultiValue, SingleValue } from "react-select";
import {
  GameResults,
  SeasonTypes,
  TableTypes,
  TeamDnaDataTypes,
} from "../../types/enums/teamDna.enums";
import { Dayjs } from "dayjs";
import { TeamLeagueData } from "../../swagger";

export type AllTeamsData = {
  [TableTypes.OFFENSE]: Array<TeamLeagueData> | [];
  [TableTypes.DEFENSE]: Array<TeamLeagueData> | [];
};

export type SelectedTeamData = {
  title?: string;
  stat?: string;
  value?: string;
  rank?: number | string;
  leagueAvg?: string;
};

export type AllSelectedTeamData = {
  [TableTypes.OFFENSE]: Array<SelectedTeamData> | [];
  [TableTypes.DEFENSE]: Array<SelectedTeamData> | [];
};

export type AllLoader = {
  [TeamDnaDataTypes.TEAM_DNA]: {
    [TableTypes.OFFENSE]: boolean;
    [TableTypes.DEFENSE]: boolean;
  };
  [TeamDnaDataTypes.PLAY_TYPES]: {
    [TableTypes.OFFENSE]: boolean;
    [TableTypes.DEFENSE]: boolean;
  };
};

export type SelectSingleValueType = SingleValue<{
  value: string;
  label: string;
  id: number;
}>;

export type MultiValuePlayersType = MultiValue<{
  value: number;
  label: string;
  isDisabled: boolean;
}> | null;

export type MultiValueType = MultiValue<{ value: string; label: string }>;

export const defaultFilter: FiltersSelectedType = {
  selectedTeam: {
    value: "PHX",
    label: "Phoenix Suns",
    id: 1610612756,
  },
  selectedOpponent: null,
  selectedSeasons: [{ value: "2023-24", label: "2023-24" }],
  isPerGameSelected: false,
  isGarbageExcluded: false,
  selectedGameResults: [GameResults.WIN, GameResults.LOSS],
  selectedSeasonTypes: [SeasonTypes.REGULAR_SEASON],
  dateRange: [null, null],
  noOfGames: undefined,
  gamesWithPlayer: [],
  gamesWithoutPlayer: [],
  gamesStartedByPlayer: [],
  gamesNotStartedByPlayer: [],
};
export class FiltersSelectedType {
  selectedTeam?: SelectSingleValueType;
  selectedOpponent: SelectSingleValueType;
  selectedSeasons: MultiValueType;
  selectedSeasonTypes: SeasonTypes[];
  dateRange: [Dayjs | null, Dayjs | null];
  noOfGames?: number;
  selectedGameResults: GameResults[];
  isPerGameSelected: boolean;
  gamesWithPlayer: MultiValuePlayersType;
  gamesWithoutPlayer: MultiValuePlayersType;
  gamesStartedByPlayer: MultiValuePlayersType;
  gamesNotStartedByPlayer: MultiValuePlayersType;
  isGarbageExcluded: boolean;

  constructor(initialData: FiltersSelectedType) {
    this.selectedTeam = initialData
      ? initialData.selectedTeam
      : defaultFilter?.selectedTeam;
    this.selectedOpponent = initialData
      ? initialData.selectedOpponent
      : defaultFilter?.selectedOpponent;
    this.selectedSeasons = initialData
      ? initialData.selectedSeasons
      : defaultFilter?.selectedSeasons;
    this.isPerGameSelected = initialData
      ? initialData.isPerGameSelected
      : defaultFilter?.isPerGameSelected;
    this.isGarbageExcluded = initialData
      ? initialData.isGarbageExcluded
      : defaultFilter?.isGarbageExcluded;
    this.selectedGameResults = initialData
      ? initialData.selectedGameResults
      : defaultFilter?.selectedGameResults;
    this.selectedSeasonTypes = initialData
      ? initialData.selectedSeasonTypes
      : defaultFilter?.selectedSeasonTypes;
    this.dateRange = initialData
      ? initialData.dateRange
      : defaultFilter?.dateRange;
    this.noOfGames = initialData
      ? initialData.noOfGames
      : defaultFilter?.noOfGames;
    this.gamesWithPlayer = initialData
      ? initialData.gamesWithPlayer
      : defaultFilter?.gamesWithPlayer;
    this.gamesWithoutPlayer = initialData
      ? initialData.gamesWithoutPlayer
      : defaultFilter?.gamesWithoutPlayer;
    this.gamesStartedByPlayer = initialData
      ? initialData.gamesStartedByPlayer
      : defaultFilter?.gamesStartedByPlayer;
    this.gamesNotStartedByPlayer = initialData
      ? initialData.gamesNotStartedByPlayer
      : defaultFilter?.gamesNotStartedByPlayer;
  }
}

export type FilterData = [
  seasonTypes: Array<string>,
  teamAbbr: string,
  gameResult: Array<string>,
  seasons?: Array<string>,
  opponentAbbr?: string,
  garbageTime?: boolean,
  isPer100Possession?: boolean,
  dateRange?: Array<string>,
  noOfGames?: number,
  includedPlayerId?: Array<string>,
  excludedPlayerId?: Array<string>,
  startedByPlayerId?: Array<string>,
  notStartedByPlayerId?: Array<string>,
];

export class SetTeamDNAData {
  Offense: Array<SelectedTeamData> = [
    {
      title: "FIVE FACTORS",
    },
    {
      stat: "ORtg",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "eFG%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "TOV%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "ORB%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "FTA Rate",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      title: "SHOOTING/SCORING",
    },
    {
      stat: "3PA",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "3P%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "FT%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "PITP",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "PLAYER ADJUSTED SHOT QUALITY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "AST%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "Rim FG%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "Rim Rate",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      title: "PACE/TRANSITION/MISC",
    },
    {
      stat: "AVG POSSESSION TIME",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "TRANSITION PTS",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "AVG NO. PLAYERS CRASHING",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
  ];

  Defense: Array<SelectedTeamData> = [
    {
      title: "FIVE FACTORS",
    },
    {
      stat: "DRtg",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "Opp. eFG%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "TURNOVERS FORCED RATE",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "DRB%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "Opp. FTA Rate",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      title: "SHOOTING/SCORING",
    },
    {
      stat: "3PA",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "3P%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "FT%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "PITP ALLOWED",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "Opp. PLAYER ADJUSTED SHOT QUALITY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "Opp. Rim FG%",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "Opp. Rim Rate",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      title: "PACE/TRANSITION",
    },
    {
      stat: "TRANSITION PTS ALLOWED",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      title: "HUSTLE STATS",
    },
    {
      stat: "STL",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "BLK",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "DEFLECTIONS",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "FOULS COMMITTED",
      value: "--",
      rank: 0,
      leagueAvg: "--",
    },
    {
      stat: "CHARGES DRAWN",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
  ];
}

export class SetPlayTypesTeamDNAData {
  Offense: Array<SelectedTeamData> = [
    {
      title: "PLAY TYPES",
    },
    {
      stat: "P&R VOLUME",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "P&R EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: " ISO VOLUME",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "ISO EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "DHO VOLUME",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: " DHO EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "POST UP VOLUME",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "POST UP EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "OFFBALL SCREEN VOLUME",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "OFFBALL SCREEN EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: " DRIVE VOLUME",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "DRIVE EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
  ];

  Defense: Array<SelectedTeamData> = [
    {
      title: "PLAY TYPES",
    },
    {
      stat: "P&R EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "ISO EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "DHO EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "POST UP EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "OFFBALL SCREEN EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
    {
      stat: "DRIVE EFFICIENCY",
      value: "--",
      rank: "--",
      leagueAvg: "--",
    },
  ];
}
