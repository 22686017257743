import { NavLink, useLocation } from "react-router-dom";
import { URL } from "../../shared/utils/route-constant";
import "./header.scss";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { ExternalLink } from "@suns/design-system/icons";

const Header = () => {
  const location = useLocation();
  const componentRef = useSelector(
    (state: { printRef: { componentRef: React.ReactInstance | null } }) =>
      state.printRef.componentRef
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef,
  });
  const showPrinter = location.pathname.includes(URL.TEAM.path);

  if (["/", "/glossary"].includes(location.pathname)) {
    return <div className="header-empty"></div>;
  }

  return (
    <header className="header-main">
      <nav>
        {location.pathname.startsWith(`/${URL.TEAM.path}`) && (
          <ul>
            <li>
              <NavLink
                to={URL.TEAM.path}
                className={({ isActive, isPending }) =>
                  isActive ? "active" : isPending ? "pending" : ""
                }
                end
              >
                Team DNA
              </NavLink>
            </li>
          </ul>
        )}
        {location.pathname.startsWith(`/${URL.GAME_DNA.path}`) && (
          <ul>
            <li>
              <NavLink
                to={`${URL.GAME_DNA.path}/latest`}
                className={({ isActive, isPending }) =>
                  isActive ? "active" : isPending ? "pending" : ""
                }
                end
              >
                Latest game
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${URL.GAME_DNA.path}`}
                className={({ isActive, isPending }) =>
                  isActive ? "active" : isPending ? "pending" : ""
                }
                end
              >
                All games
              </NavLink>
            </li>
          </ul>
        )}
        {location.pathname.startsWith(
          `/${URL.DASHBOARD.children.BOX_SCORES.path}`
        ) && (
          <ul>
            <li>
              <NavLink
                to={URL.DASHBOARD.children.BOX_SCORES.path}
                className={({ isActive, isPending }) =>
                  isActive ? "active" : isPending ? "pending" : ""
                }
                end
              >
                Boxscore
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${URL.DASHBOARD.children.BOX_SCORES.path}/${URL.DASHBOARD.children.BOX_SCORES.children.LINEUPS.path}`}
                className={({ isActive, isPending }) =>
                  isActive ? "active" : isPending ? "pending" : ""
                }
                end
              >
                Lineups
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${URL.DASHBOARD.children.BOX_SCORES.path}/${URL.DASHBOARD.children.BOX_SCORES.children.REBOUNDING.path}`}
                className={({ isActive, isPending }) =>
                  isActive ? "active" : isPending ? "pending" : ""
                }
                end
              >
                Rebounding
              </NavLink>
            </li>
            <li>
              <a
                href="https://personnel.phxapollo.com/"
                target="_blank"
                className="items-center gap-4"
              >
                Personnel <ExternalLink className="size-4" />
              </a>
            </li>
          </ul>
        )}
        {location.pathname.startsWith(`/${URL.LABS.path}`) && (
          <ul>
            <li>
              <NavLink
                to={URL.LABS.path}
                className={({ isActive, isPending }) =>
                  isActive ? "active" : isPending ? "pending" : ""
                }
                end
              >
                All Labs
              </NavLink>
            </li>
          </ul>
        )}
        {location.pathname.startsWith(`/${URL.REPORTS.path}`) && (
          <ul>
            <li>
              <NavLink
                to={`${URL.REPORTS.path}/${URL.REPORTS.children.MY_REPORTS.path}`}
                className={({ isActive, isPending }) =>
                  isActive ? "active" : isPending ? "pending" : ""
                }
                end
              >
                My Reports
              </NavLink>
            </li>
          </ul>
        )}
      </nav>
      <div className="header-middle"></div>
      {showPrinter ? (
        <div className="print">
          <a
            className={`${!componentRef ? "disabled" : ""}`}
            onClick={componentRef ? handlePrint : () => {}}
          >
            <i className="cs-icon icon-print"></i>
            <span>Print</span>
          </a>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
