/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvancedBoxResponse } from '../models/AdvancedBoxResponse';
import type { BoxScoreResponse } from '../models/BoxScoreResponse';
import type { CheckIsDataFinalizedResponse } from '../models/CheckIsDataFinalizedResponse';
import type { CrashersResponse } from '../models/CrashersResponse';
import type { FrequentPlayerActionsResponse } from '../models/FrequentPlayerActionsResponse';
import type { GameResponse } from '../models/GameResponse';
import type { GamesResponse } from '../models/GamesResponse';
import type { LineupsResponse } from '../models/LineupsResponse';
import type { PlayTypesResponse } from '../models/PlayTypesResponse';
import type { RecentGameResponse } from '../models/RecentGameResponse';
import type { SavedNoteResponse } from '../models/SavedNoteResponse';
import type { SaveNoteBody } from '../models/SaveNoteBody';
import type { SaveNoteResponse } from '../models/SaveNoteResponse';
import type { ShootingResponse } from '../models/ShootingResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GameDnaService {
    /**
     * @param gameId
     * @param teamId
     * @returns GameResponse Success.
     * @throws ApiError
     */
    public static getGame(
        gameId: string,
        teamId?: number,
    ): CancelablePromise<GameResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/game',
            query: {
                'gameId': gameId,
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param season
     * @param teamId
     * @returns GamesResponse Success.
     * @throws ApiError
     */
    public static getGames(
        season: string,
        teamId?: number,
    ): CancelablePromise<GamesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/games',
            query: {
                'teamId': teamId,
                'season': season,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @returns RecentGameResponse Success.
     * @throws ApiError
     */
    public static getLatestMatch(): CancelablePromise<RecentGameResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/latest-match',
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param gameId
     * @param teamId
     * @returns AdvancedBoxResponse Success.
     * @throws ApiError
     */
    public static getAdvancedBoxData(
        gameId: string,
        teamId?: number,
    ): CancelablePromise<AdvancedBoxResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/advanced-box',
            query: {
                'gameId': gameId,
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param gameId
     * @param teamId
     * @returns BoxScoreResponse Success.
     * @throws ApiError
     */
    public static getBoxScoreData(
        gameId: string,
        teamId?: number,
    ): CancelablePromise<BoxScoreResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/box-score',
            query: {
                'gameId': gameId,
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param gameId
     * @param teamId
     * @returns PlayTypesResponse Success.
     * @throws ApiError
     */
    public static getPlayTypesData(
        gameId: string,
        teamId?: number,
    ): CancelablePromise<PlayTypesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/play-types',
            query: {
                'gameId': gameId,
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param lineups
     * @param gameId
     * @param teamId
     * @param limit
     * @param offset
     * @param orderBy
     * @param orderDir
     * @returns LineupsResponse Success.
     * @throws ApiError
     */
    public static getLineups(
        lineups: string,
        gameId: string,
        teamId?: number,
        limit: number = 25,
        offset?: number,
        orderBy: string = 'MP',
        orderDir: string = 'desc',
    ): CancelablePromise<LineupsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/lineups/{lineups}',
            path: {
                'lineups': lineups,
            },
            query: {
                'gameId': gameId,
                'teamId': teamId,
                'limit': limit,
                'offset': offset,
                'orderBy': orderBy,
                'orderDir': orderDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param gameId
     * @param teamId
     * @returns FrequentPlayerActionsResponse Success.
     * @throws ApiError
     */
    public static getFrequentPlayerActions(
        gameId: string,
        teamId?: number,
    ): CancelablePromise<FrequentPlayerActionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/frequent-player-actions',
            query: {
                'gameId': gameId,
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param gameId
     * @param teamId
     * @returns ShootingResponse Success.
     * @throws ApiError
     */
    public static getShooting(
        gameId: string,
        teamId?: number,
    ): CancelablePromise<ShootingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/shooting',
            query: {
                'gameId': gameId,
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param gameId
     * @param teamId
     * @returns CrashersResponse Success.
     * @throws ApiError
     */
    public static getCrashers(
        gameId: string,
        teamId?: number,
    ): CancelablePromise<CrashersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/crashers',
            query: {
                'gameId': gameId,
                'teamId': teamId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param gameId
     * @returns CheckIsDataFinalizedResponse Success.
     * @throws ApiError
     */
    public static checkIsDataFinalized(
        gameId: string,
    ): CancelablePromise<CheckIsDataFinalizedResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/is-data-finalized',
            query: {
                'gameId': gameId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns SaveNoteResponse Success.
     * @throws ApiError
     */
    public static saveNote(
        requestBody: SaveNoteBody,
    ): CancelablePromise<SaveNoteResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/game-dna/note',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param gameId
     * @returns SavedNoteResponse Success.
     * @throws ApiError
     */
    public static getNote(
        gameId: string,
    ): CancelablePromise<SavedNoteResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/game-dna/note',
            query: {
                'gameId': gameId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
}
