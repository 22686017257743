import { AccountInfo } from "@azure/msal-browser";
import { msalInstance } from "./instance";

type AccountWithRoles = {
  isAuthenticated: boolean;
  hasDefaultAccess: boolean;
  hasDeveloperAccess: boolean;
  hasBetaScoutingAccess: boolean;
  hasBetaShinyAccess: boolean;
  name: string | null;
  info: AccountInfo | null;
};

export function getAccount() {
  const [account] = msalInstance.getAllAccounts();
  return addRolesToAccount(account);
}

export function addRolesToAccount(
  account?: AccountInfo | null
): AccountWithRoles {
  if (account === undefined || account === null) {
    return {
      isAuthenticated: false,
      hasDefaultAccess: false,
      hasDeveloperAccess: false,
      hasBetaScoutingAccess: false,
      hasBetaShinyAccess: false,
      info: null,
      name: null,
    };
  }

  const isDeveloper =
    account.idTokenClaims?.roles?.includes("Developer") || false;

  return {
    isAuthenticated: true,
    hasDefaultAccess:
      account.idTokenClaims?.roles?.includes("Default") || false,
    hasDeveloperAccess: isDeveloper,
    hasBetaScoutingAccess:
      isDeveloper ||
      account.idTokenClaims?.roles?.includes("BetaScouting") ||
      false,
    hasBetaShinyAccess:
      isDeveloper ||
      account.idTokenClaims?.roles?.includes("BetaShiny") ||
      false,
    name: formatAccountName(account.name),
    info: account,
  };
}

function formatAccountName(name?: string) {
  if (!name) {
    return "";
  }

  if (!name.includes(",")) {
    return name;
  }

  return name.split(",").splice(1).join(" ").trim();
}
