export const teams = [
  {
    TEAM_ID: 1610612737,
    TEAM_ABBR: "ATL",
    TEAM_FULL_NAME: "Atlanta Hawks",
    TEAM_COLOR: "#E03A3E",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612738,
    TEAM_ABBR: "BOS",
    TEAM_FULL_NAME: "Boston Celtics",
    TEAM_COLOR: "#007A33",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612739,
    TEAM_ABBR: "CLE",
    TEAM_FULL_NAME: "Cleveland Cavaliers",
    TEAM_COLOR: "#860038",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612740,
    TEAM_ABBR: "NOP",
    TEAM_FULL_NAME: "New Orleans Pelicans",
    TEAM_COLOR: "#0C2340",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612741,
    TEAM_ABBR: "CHI",
    TEAM_FULL_NAME: "Chicago Bulls",
    TEAM_COLOR: "#CE1141",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612742,
    TEAM_ABBR: "DAL",
    TEAM_FULL_NAME: "Dallas Mavericks",
    TEAM_COLOR: "#00538C",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612743,
    TEAM_ABBR: "DEN",
    TEAM_FULL_NAME: "Denver Nuggets",
    TEAM_COLOR: "#0E2240",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612744,
    TEAM_ABBR: "GSW",
    TEAM_FULL_NAME: "Golden State Warriors",
    TEAM_COLOR: "#1D428A",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612745,
    TEAM_ABBR: "HOU",
    TEAM_FULL_NAME: "Houston Rockets",
    TEAM_COLOR: "#CE1141",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612746,
    TEAM_ABBR: "LAC",
    TEAM_FULL_NAME: "LA Clippers",
    TEAM_COLOR: "#C8102E",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612747,
    TEAM_ABBR: "LAL",
    TEAM_FULL_NAME: "Los Angeles Lakers",
    TEAM_COLOR: "#552583",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612748,
    TEAM_ABBR: "MIA",
    TEAM_FULL_NAME: "Miami Heat",
    TEAM_COLOR: "#98002E",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612749,
    TEAM_ABBR: "MIL",
    TEAM_FULL_NAME: "Milwaukee Bucks",
    TEAM_COLOR: "#00471B",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612750,
    TEAM_ABBR: "MIN",
    TEAM_FULL_NAME: "Minnesota Timberwolves",
    TEAM_COLOR: "#0C2340",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612751,
    TEAM_ABBR: "BKN",
    TEAM_FULL_NAME: "Brooklyn Nets",
    TEAM_COLOR: "#000000",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612752,
    TEAM_ABBR: "NYK",
    TEAM_FULL_NAME: "New York Knicks",
    TEAM_COLOR: "#006BB6",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612753,
    TEAM_ABBR: "ORL",
    TEAM_FULL_NAME: "Orlando Magic",
    TEAM_COLOR: "#0077C0",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612754,
    TEAM_ABBR: "IND",
    TEAM_FULL_NAME: "Indiana Pacers",
    TEAM_COLOR: "#002D62",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612755,
    TEAM_ABBR: "PHI",
    TEAM_FULL_NAME: "Philadelphia 76ers",
    TEAM_COLOR: "#006BB6",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612756,
    TEAM_ABBR: "PHX",
    TEAM_FULL_NAME: "Phoenix Suns",
    TEAM_COLOR: "#1D1160",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612757,
    TEAM_ABBR: "POR",
    TEAM_FULL_NAME: "Portland Trail Blazers",
    TEAM_COLOR: "#E03A3E",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612758,
    TEAM_ABBR: "SAC",
    TEAM_FULL_NAME: "Sacramento Kings",
    TEAM_COLOR: "#5A2D81",
    DIVISION: "Pacific",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612759,
    TEAM_ABBR: "SAS",
    TEAM_FULL_NAME: "San Antonio Spurs",
    TEAM_COLOR: "#C4CED4",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612760,
    TEAM_ABBR: "OKC",
    TEAM_FULL_NAME: "Oklahoma City Thunder",
    TEAM_COLOR: "#007AC1",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612761,
    TEAM_ABBR: "TOR",
    TEAM_FULL_NAME: "Toronto Raptors",
    TEAM_COLOR: "#CE1141",
    DIVISION: "Atlantic",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612762,
    TEAM_ABBR: "UTA",
    TEAM_FULL_NAME: "Utah Jazz",
    TEAM_COLOR: "#002B5C",
    DIVISION: "Northwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612763,
    TEAM_ABBR: "MEM",
    TEAM_FULL_NAME: "Memphis Grizzlies",
    TEAM_COLOR: "#5D76A9",
    DIVISION: "Southwest",
    CONFERENCE: "Western",
  },
  {
    TEAM_ID: 1610612764,
    TEAM_ABBR: "WAS",
    TEAM_FULL_NAME: "Washington Wizards",
    TEAM_COLOR: "#002B5C",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612765,
    TEAM_ABBR: "DET",
    TEAM_FULL_NAME: "Detroit Pistons",
    TEAM_COLOR: "#C8102E",
    DIVISION: "Central",
    CONFERENCE: "Eastern",
  },
  {
    TEAM_ID: 1610612766,
    TEAM_ABBR: "CHA",
    TEAM_FULL_NAME: "Charlotte Hornets",
    TEAM_COLOR: "#1D1160",
    DIVISION: "Southeast",
    CONFERENCE: "Eastern",
  },
];

export const suns = {
  TEAM_ID: 1610612756,
  TEAM_ABBR: "PHX",
  TEAM_FULL_NAME: "Phoenix Suns",
};
