import { TeamLogo } from "@/components";
import { Button, cn, Flex, Grid, Skeleton, Text } from "@suns/design-system";

interface Game {
  gameId: string;
  gameTime: string;
  homeTeamId: number;
  awayTeamId: number;
  homeTeam: string;
  awayTeam: string;
  arena: string;
}

interface GameSelectionProps {
  schedule: Game[];
  loading: boolean;
  error: string;
  handleSelectGame: (game: Game) => void;
  selectedGame: Game | null;
}

export default function GameSelection({
  schedule,
  loading,
  error,
  handleSelectGame,
  selectedGame,
}: GameSelectionProps) {
  return (
    <>
      {error ? (
        <Text>Something went wrong</Text>
      ) : (
        <>
          {loading ? (
            <Grid gap="md" columns="4" className="pt-3">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                <Skeleton key={item} className="h-20 w-full" />
              ))}
            </Grid>
          ) : (
            <>
              {schedule?.length ? (
                <>
                  <Grid
                    gap="md"
                    columns="4"
                    className="mt-4 xs:max-lg:grid-cols-1"
                  >
                    {schedule?.map((game) => (
                      <Button
                        variant="outline"
                        onClick={() => handleSelectGame(game)}
                        className={cn(
                          "cursor-pointer py-16 hover:bg-gray-100",
                          selectedGame?.gameId === game.gameId &&
                            "border-2 border-black bg-gray-100"
                        )}
                        key={game.gameId}
                      >
                        <Flex direction="down" justify="center" align="center">
                          <Text
                            className="rounded-xl bg-gray-200 px-2 py-1 font-medium text-gray-700"
                            size="xs"
                            heading
                          >
                            {game.gameTime} MT
                          </Text>
                          <Flex
                            className="py-2"
                            justify="center"
                            align="center"
                          >
                            <Flex gap="sm" justify="center" align="center">
                              <TeamLogo teamId={game.awayTeamId} size={"xs"} />
                              <Text heading size="sm">
                                {game.awayTeam}
                              </Text>
                            </Flex>
                            <Text className="px-2" size="sm">
                              @
                            </Text>
                            <Flex gap="sm" justify="center" align="center">
                              <Text heading size="sm">
                                {game.homeTeam}
                              </Text>
                              <TeamLogo teamId={game.homeTeamId} size={"xs"} />
                            </Flex>
                          </Flex>
                          <Text size="xs" className="text-gray-500">
                            {game.arena}
                          </Text>
                        </Flex>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                <Flex className="my-10" justify="center">
                  <Text size="md" muted>
                    No games are available for the selected date.
                  </Text>
                </Flex>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
