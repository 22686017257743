import { useRouteError } from "react-router-dom";
import { notify } from "../bugsnag";
import { Container, Flex, Text } from "@suns/design-system";
import { CircleAlert } from "@suns/design-system/icons";

const ErrorFallback = () => {
  const error = useRouteError();
  if (error) {
    notify(error as Error);
  }

  return (
    <Container size="sm" className="pt-4">
      <Flex direction="down" align="center" gap="md">
        <Flex align="center" gap="md">
          <CircleAlert className="size-7 text-destructive lg:size-8" />
          <Text size={["lg", "lg:2xl"]} heading>
            Something went wrong.
          </Text>
        </Flex>
        <Text className="text-center">
          We've been alerted that there was an issue. We'll do our best to
          address the problem in a timely manner. We apologize for any
          inconvenience.
        </Text>
      </Flex>
    </Container>
  );
};

export default ErrorFallback;
