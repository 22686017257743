import React from "react";

import { type VariantParams, classVariants, cn } from "../../utils";

const containerVariants = classVariants(["mx-auto"], {
  variants: {
    size: {
      sm: "max-w-screen-sm",
      md: "max-w-screen-md",
      lg: "max-w-screen-lg",
      xl: "max-w-screen-xl",
      "2xl": "max-w-screen-2xl",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export interface ContainerProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantParams<typeof containerVariants> {}

export function Container({
  className,
  size,
  children,
  ...props
}: ContainerProps) {
  return (
    <div
      className={cn(
        containerVariants({
          size,
        }),
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
