import { Select, SelectOption } from "@suns/design-system";

interface LeagueSelectProps {
  leagueList: string[];
  selectedLeague: string;
  setSelectedLeague: (league: string) => void;
}

export default function LeagueSelect({
  leagueList,
  selectedLeague,
  setSelectedLeague,
}: LeagueSelectProps) {
  return (
    <Select
      className="bg-white"
      value={selectedLeague}
      onValueChange={setSelectedLeague}
    >
      {leagueList.map((league) => (
        <SelectOption key={league} value={league}>
          {league}
        </SelectOption>
      ))}
    </Select>
  );
}
