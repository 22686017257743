import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Grid,
  Container,
  Select,
  SelectOption,
  SelectGroup,
  SkeletonHeading,
  Skeleton,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks";
import { loader } from "./game-dna-listings-loader";
import { GameCard, GameHero, GameHeroSkeleton } from "./components";

export function GameDnaListings() {
  const { loading, response } = useAsync(loader);
  const [selectedTeamId, setSelectedTeamId] = useState<string>("all");
  const filteredGames = useMemo(() => {
    if (!response) {
      return [];
    }
    const { pastGames } = response.games;
    if (selectedTeamId == "all") {
      return pastGames;
    }
    return pastGames.filter(({ homeTeamId, awayTeamId }) => {
      return (
        `${homeTeamId}` == selectedTeamId || `${awayTeamId}` == selectedTeamId
      );
    });
  }, [response, selectedTeamId]);

  if (loading) {
    return <GameDnaListingsSkeleton />;
  }

  if (response == null) {
    throw Error("Error loading page");
  }

  const { latestGame } = response.games;
  const { western, eastern } = response.teams;
  return (
    <>
      {latestGame ? (
        <Container>
          <Link to={latestGame.gameId}>
            <Card
              badge="Latest game"
              badgeProps={{ variant: "primary", muted: true }}
              className="my-2"
            >
              <GameHero {...latestGame} />
            </Card>
          </Link>
        </Container>
      ) : null}
      {filteredGames ? (
        <Container>
          <Card
            header={
              <Select
                value={selectedTeamId}
                onValueChange={setSelectedTeamId}
                placeholder="Select a team..."
              >
                <SelectOption value="all">All Games</SelectOption>
                <SelectGroup label="Western">
                  {western.map(({ id, name }) => (
                    <SelectOption key={`option-${id}`} value={id}>
                      {name}
                    </SelectOption>
                  ))}
                </SelectGroup>
                <SelectGroup label="Eastern">
                  {eastern.map(({ id, name }) => (
                    <SelectOption key={`option-${id}`} value={id}>
                      {name}
                    </SelectOption>
                  ))}
                </SelectGroup>
              </Select>
            }
          >
            <Grid columns="sm:2" gap="lg">
              {filteredGames.map((game) => (
                <GameCard key={`game-${game.gameId}`} {...game} />
              ))}
            </Grid>
          </Card>
        </Container>
      ) : null}
    </>
  );
}

function GameDnaListingsSkeleton() {
  return (
    <>
      <Container>
        <Card
          badge="Latest game"
          badgeProps={{ muted: true }}
          className="my-2 hover:bg-gray-50"
        >
          <GameHeroSkeleton />
        </Card>
      </Container>
      <Container>
        <Card header={<SkeletonHeading />}>
          <Grid columns="sm:2" gap="md">
            {[...Array(4).keys()].map((i) => (
              <Skeleton key={`sk-${i}`} className="h-[40px] w-full" />
            ))}
          </Grid>
        </Card>
      </Container>
    </>
  );
}
