import React from "react";

import { type VariantParams, classVariants, cn } from "../../utils";

const flexVariants = classVariants(["flex"], {
  variants: {
    direction: {
      right: "flex-row",
      left: "flex-row-reverse",
      down: "flex-col",
      up: "flex-col-reverse",
    },
    wrap: {
      true: "flex-wrap",
      false: "flex-nowrap",
    },
    justify: {
      normal: "justify-normal",
      start: "justify-start",
      end: "justify-end",
      center: "justify-center",
      between: "justify-between",
      around: "justify-around",
      evenly: "justify-evenly",
      stretch: "justify-stretch",
    },
    align: {
      start: "items-start",
      end: "items-end",
      center: "items-center",
      baseline: "items-baseline",
      stretch: "items-stretch",
    },
    gap: {
      xs: "gap-xs",
      sm: "gap-sm",
      md: "gap-md",
      lg: "gap-lg",
      xl: "gap-xl",
    },
    verticalGap: {
      xs: "gap-y-1",
      sm: "gap-y-2",
      md: "gap-y-4",
      lg: "gap-y-6",
      xl: "gap-y-8",
    },
  },
  defaultVariants: {
    wrap: false,
  },
});

export interface FlexProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantParams<typeof flexVariants> {}

export function Flex({
  className,
  direction,
  wrap,
  justify,
  align,
  gap,
  verticalGap,
  children,
  ...props
}: FlexProps) {
  return (
    <div
      className={cn(
        flexVariants({
          direction,
          wrap,
          justify,
          align,
          gap,
          verticalGap,
        }),
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
