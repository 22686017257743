import { useParams } from "react-router-dom";
import { useAsync } from "@/shared/hooks";
import { gameLoader } from "./loaders/game-loader";
import { Card, Flex, Text } from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";
import { GameHero, GameHeroSkeleton } from "@/components/GameHero/GameHero";

export function CreateGameReport() {
  const { gameId } = useParams();
  const gameStream = useAsync(gameLoader, {
    gameId: gameId || "",
  });

  if (gameStream.loading && !gameStream.response) {
    return <CreateGameReportLoading />;
  }

  if (!gameStream.response || !gameId) {
    if (gameStream.error) {
      throw gameStream.error;
    }
    throw Error("There was an error retrieving game data.");
  }

  const { game } = gameStream.response;

  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Create Report</Text>
      </Flex>
      <Card className="mb-4 overflow-hidden">
        <GameHero
          gameId={gameId}
          gameStatus={game.gameStatus || 1}
          gameTimeUtc={game.gameTimeUTC!}
          gameClock={game.gameClock!}
          period={game.period!}
          homeTeam={{
            id: game.homeTeam!.teamId!,
            tricode: game.homeTeam!.teamTricode!,
            score: game.homeTeam!.score!,
          }}
          awayTeam={{
            id: game.awayTeam!.teamId!,
            tricode: game.awayTeam!.teamTricode!,
            score: game.awayTeam!.score!,
          }}
        />
      </Card>
    </>
  );
}

function CreateGameReportLoading() {
  return (
    <>
      <Card className="mb-4">
        <GameHeroSkeleton />
      </Card>
    </>
  );
}
