import { useMemo } from "react";
import dayjs from "dayjs";
import {
  Flex,
  Skeleton,
  SkeletonHeading,
  SkeletonThumbnail,
  Text,
  Badge,
} from "@suns/design-system";
import { TeamLogo } from "@/components";
import { LeagueId } from "@suns/api";
import { getLeagueIdFromGameId } from "@/shared/utils";

interface Team {
  id: number;
  tricode: string;
  score: number;
}

type Game = {
  gameId: string;
  homeTeam: Team;
  awayTeam: Team;
  gameStatus: number;
  gameClock: string;
  gameTimeUtc: string;
  period: number;
};

export function GameHero({ ...props }: Game) {
  const {
    gameId,
    homeTeam,
    awayTeam,
    gameStatus,
    period,
    gameClock,
    gameTimeUtc,
  } = props;
  const date = useMemo(() => dayjs(gameTimeUtc || ""), [gameTimeUtc]);
  const status = {
    1: "Pregame",
    2: "Live",
    3: "Final",
  }[gameStatus];
  const leagueId = getLeagueIdFromGameId(gameId);
  const { minute, second } = splitGameClock(gameClock);

  return (
    <Flex direction="down" align="center" gap="sm">
      <Flex align="center" gap="md">
        <Text size="sm">{date.format("MMMM D, YYYY")}</Text>
        <Badge variant={status == "Live" ? "destructive" : "default"}>
          {status}
        </Badge>
      </Flex>
      {status == "Live" && (
        <Flex gap="sm">
          <Text size="xl">Q{period}</Text>
          <Text size="xl">
            {minute}:{second}
          </Text>
        </Flex>
      )}
      <Flex gap="md" align="center" justify="center">
        <GameHeroTeam {...awayTeam} leagueId={leagueId} />
        <Text size={["sm", "md:xl"]}>{"@"}</Text>
        <GameHeroTeam
          {...homeTeam}
          direction="left"
          isHome
          leagueId={leagueId}
        />
      </Flex>
    </Flex>
  );
}

function GameHeroTeam({
  id,
  tricode,
  score,
  isHome,
  leagueId,
  direction = "right",
}: Pick<Team, "id" | "tricode" | "score"> & {
  isHome?: boolean;
  direction?: "right" | "left";
  leagueId?: LeagueId;
}) {
  return (
    <Flex direction={direction} gap="sm" align="center" wrap>
      <Flex direction="down">
        <Text size={["md", "sm:2xl"]} heading>
          {tricode}
        </Text>
        <Text size={["sm", "md:md"]}>{isHome ? "Home" : "Away"}</Text>
      </Flex>
      <TeamLogo teamId={id} size={["sm", "md:md"]} leagueId={leagueId} />
      <Text size={["md", "sm:2xl"]} heading className="md:mx-md">
        {score}
      </Text>
    </Flex>
  );
}

export function GameHeroSkeleton() {
  function Team({ direction = "right" }: { direction?: "right" | "left" }) {
    return (
      <Flex direction={direction} gap="md" align="center" wrap>
        <Flex direction="down" gap="sm">
          <Skeleton className="h-[30px] w-[40px]" />
          <SkeletonHeading />
        </Flex>
        <SkeletonThumbnail />
        <Skeleton className="h-[30px] w-[40px]" />
      </Flex>
    );
  }

  return (
    <Flex direction="right" gap="md" align="center" justify="center">
      <Team />
      <SkeletonHeading />
      <Team direction="left" />
    </Flex>
  );
}

function splitGameClock(gameClock: string) {
  const [minute, second, milliSecond] = gameClock
    .replace(/PT|S/, "")
    .split(/M|\./);
  return { minute, second, milliSecond };
}
