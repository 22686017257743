import { useMemo } from "react";
import dayjs from "dayjs";
import {
  Button,
  Flex,
  Skeleton,
  SkeletonHeading,
  SkeletonThumbnail,
  Text,
} from "@suns/design-system";
import { TeamLogo } from "@/components";

import { Game } from "../../game-dna-listings-loader";

export function GameHero({ ...props }: Game) {
  const {
    teamId,
    teamAbbr,
    teamScore,
    oppTeamId,
    oppTeamAbbr,
    oppTeamScore,
    homeTeamAbbr,
    gameDate,
    gameNumber,
  } = props;
  const date = useMemo(() => dayjs(gameDate), [gameDate]);
  const isHome = teamAbbr == homeTeamAbbr;

  function GameHeroTeam({
    id,
    abbr,
    score,
    isHome,
    direction = "right",
  }: {
    id: Game["teamId"];
    abbr: Game["teamAbbr"];
    score: Game["teamScore"];
    isHome: boolean;
    direction?: "right" | "left";
  }) {
    return (
      <Flex direction={direction} gap="sm" align="center" wrap>
        <Flex direction="down">
          <Text size={["md", "sm:2xl"]} heading>
            {abbr}
          </Text>
          <Text size={["sm", "md:md"]}>{isHome ? "Home" : "Away"}</Text>
        </Flex>
        <TeamLogo teamId={id} size={["sm", "md:md"]} />
        <Text size={["md", "sm:2xl"]} heading className="md:mx-md">
          {score}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex direction="down" align="center" gap="sm">
      <Flex direction="right" gap="md" align="center" justify="center">
        <GameHeroTeam
          id={teamId}
          abbr={teamAbbr}
          score={teamScore}
          isHome={isHome}
        />
        <Text size={["sm", "md:xl"]}>{isHome ? "vs" : "@"}</Text>
        <GameHeroTeam
          direction="left"
          id={oppTeamId}
          abbr={oppTeamAbbr}
          score={oppTeamScore}
          isHome={!isHome}
        />
      </Flex>
      <Flex align="center" gap="md">
        <Text heading>Game {gameNumber}</Text>
        <Text>{date.format("MMMM D, YYYY")}</Text>
        <Button variant="link" size="flush">
          Game DNA
        </Button>
      </Flex>
    </Flex>
  );
}

export function GameHeroSkeleton() {
  function Team({ direction = "right" }: { direction?: "right" | "left" }) {
    return (
      <Flex direction={direction} gap="md" align="center" wrap>
        <Flex direction="down" gap="sm">
          <Skeleton className="h-[30px] w-[40px]" />
          <SkeletonHeading />
        </Flex>
        <SkeletonThumbnail />
        <Skeleton className="h-[30px] w-[40px]" />
      </Flex>
    );
  }

  return (
    <Flex direction="right" gap="md" align="center" justify="center">
      <Team />
      <SkeletonHeading />
      <Team direction="left" />
    </Flex>
  );
}
