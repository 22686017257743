import { getGame } from "@/shared/api";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let cache: any;

export async function gameLoader({ gameId }: { gameId: string }) {
  const game = await getGame({ gameId });
  if (game.gameStatus == 1) {
    return {
      game,
      team: null,
      oppTeam: null,
      homeTeam: null,
      awayTeam: null,
      percentiles: null,
    };
  }

  if (cache && game.gameStatusText == cache.game.gameStatusText) {
    return cache as typeof toReturn;
  }

  const toReturn = { game };
  cache = toReturn;
  return toReturn;
}
