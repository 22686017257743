import { LoaderFunction, LoaderFunctionArgs } from "react-router-dom";
import { getAccount } from "../auth";
import { redirectToLogin } from "./redirect";

export function protectedLoader(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader: LoaderFunction<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): LoaderFunction<any> {
  return async (args: LoaderFunctionArgs) => {
    const { isAuthenticated, hasDefaultAccess } = getAccount();
    if (isAuthenticated && hasDefaultAccess) {
      const response = await loader(args);
      return response;
    } else {
      const { request } = args;
      redirectToLogin(request);
    }
  };
}
