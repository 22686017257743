import { Suspense } from "react";
import SuspenseFallback from "@/components/suspense-fallback/suspense-fallback";
import ErrorFallback from "@/components/error-fallback/error-fallback";
import { RouteObject } from "react-router-dom";
import { ReportsCreate, ReportsListings, CreateGameReport } from "@/pages/reports/index";

export const ReportRoutes = {
  path: "reports",
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <ReportsListings />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "create",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <ReportsCreate />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "create/game/:gameId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <CreateGameReport />
        </Suspense>
      ),
    },
  ],
} satisfies RouteObject;

export default ReportRoutes;
