import { useState, useEffect } from "react";
import { getSchedule } from "@/shared/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

interface GameInfo {
  gameId: string | null | undefined;
  homeTeam: string | null | undefined;
  awayTeam: string | null | undefined;
  gameTime: string | undefined;
  arena: string | null | undefined;
  homeTeamId: number | null | undefined;
  awayTeamId: number | null | undefined;
}

export function useSchedule(date: string) {
  const [schedule, setSchedule] = useState<GameInfo[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        setLoading(true);
        const data = await getSchedule({
          leagueId: "00",
          date,
        });

        const formateDate = (date: string) => {
          return dayjs(date).format("MM/DD/YYYY");
        };

        const schedule = data.gameDates?.filter((game) => {
          return formateDate(game?.gameDate || "") === formateDate(date);
        });

        const games = schedule?.[0]?.games
          ?.map((game) => {
            const gameTime = dayjs
              .tz("1900-01-01T19:00:00Z", "America/Phoenix")
              .format("h:mma");

            const gameInfo = {
              gameId: game.gameId,
              homeTeamId: game.homeTeam?.teamId,
              awayTeamId: game.visitorTeam?.teamId,
              homeTeam: game.homeTeam?.teamTricode,
              awayTeam: game.visitorTeam?.teamTricode,
              gameTime: gameTime,
              arena: game.arenaName,
            };
            return gameInfo;
          })
          .filter((game) => game !== undefined);

        setSchedule(games || []);
      } catch (err) {
        setError((err as string) || "Error fetching schedule");
      } finally {
        setLoading(false);
      }
    };

    fetchSchedule();
  }, [date]);

  return { schedule, loading, error };
}
